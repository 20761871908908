import React from "react";
import { darkGray, lightPrimaryColor, offWhite } from "../constants/colors";
import HeadPatternRed from "../images/head-pattern-red.png";

const LandingHeading: React.FC<{ title: string, subtitle: string, mode?: "light" | "dark" }> = ({ title, subtitle, mode = "light" }) => {
	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center"
		}}>
			<div style={{ textAlign: "center" }}><span style={{
				fontFamily: "Tangerine, Cairo",
				fontSize: 70,
				color: lightPrimaryColor,
				fontWeight: 700
			}}>{title}</span></div>
			<div style={{ marginBottom: 15 }}><span style={{
				fontSize: 34,
				color: mode === "dark" ? darkGray : offWhite,
				fontWeight: 600,
				textTransform: "uppercase",
				lineHeight: "34px"
			}}>{subtitle}</span></div>
			<img src={HeadPatternRed}
				alt="head-pattern"
				style={{ width: "90%" }} />
		</div>
	)
}

export default LandingHeading;