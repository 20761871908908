import "./styles/global.css"
import ReactDOM from 'react-dom';
import {
	BrowserRouter,
} from "react-router-dom";
import App from "./app/App";
import { CountryContext, LanguageContext, TokenContext } from "./app/Context";
import { countries, languages } from "./app/types";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { ThemeProvider } from "@mui/material";
import { makeTheme } from "./constants/theme";
import { useMemo, useState } from "react";
import { DrawerContext } from "./context/DrawerContext";
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { prefixer } from 'stylis';
import { CommandDialogContext, CommandDialogContextType } from "./context/CommandDialogContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ScrollToTop from "./components/ScrollToTop";

const Root = () => {
	const [lang, setLang] = useLocalStorage<languages>("language", "en");
	const [token, setToken] = useLocalStorage<string | null>("token", null);
	const [country, setCountry] = useLocalStorage<countries | undefined>("country", undefined);
	const drawerState = useState<boolean>(false);
	const commandState = useState<CommandDialogContextType>({
		isOpen: false,
		spec: undefined
	});

	// MUIV5 RTL Support ...
	const cacheRtl = useMemo(() => createCache({
		key: lang === "en" ? 'mui' : 'muirtl',
		stylisPlugins: [
			prefixer,
			...(
				lang === "en"
					? []
					: [rtlPlugin])

		],
	}), [lang]);

	const theme = useMemo(() => makeTheme(lang === "en" ? "ltr" : "rtl"), [lang]);

	return (
		<LocalizationProvider 
			dateAdapter={AdapterLuxon}
			adapterLocale={lang}
		 >
			<CacheProvider value={cacheRtl}>
				<ThemeProvider theme={theme}>
					<LanguageContext.Provider value={[lang, setLang]}>
						<TokenContext.Provider value={[token, setToken]}>
							<CountryContext.Provider value={[country, setCountry]}>
								<DrawerContext.Provider value={drawerState}>
									<CommandDialogContext.Provider value={commandState}>
										<BrowserRouter>
											<ScrollToTop />
											<App />
										</BrowserRouter>
									</CommandDialogContext.Provider>
								</DrawerContext.Provider>
							</CountryContext.Provider>
						</TokenContext.Provider>
					</LanguageContext.Provider>
				</ThemeProvider>
			</CacheProvider>
		</LocalizationProvider>
	);
}

ReactDOM.render(
	<Root />,
	document.getElementById('root')
);
