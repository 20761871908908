import { lightPrimaryColor, primaryColor } from "../constants/colors"
import { useCountry } from "../hooks/useCountry"
import { translate } from "../hooks/useTranslate"

export const CountryPicker = () => {
	const [, setCountry] = useCountry();
	return (
		<div style={{backgroundColor: "black",		
			position: "absolute",
			zIndex: 41,
			width: "100%",
			height: "100%",
			display: "flex",
			flexDirection : "column",
			gap: 10,
			alignItems :"center",
			justifyContent: "center",
		}}
		>
			<span style={{color: lightPrimaryColor,
				fontSize: 40}}>
				{translate("select_your_country")}
			</span>
			<div 
				onClick={() => setCountry("kw")}
				style={{
					color: "white",
					cursor: "pointer",
					padding: "10px 30px",
					display: "flex",
					width: "max-width",
					flexDirection: "row",
					alignItems: "center",
					gap: 10,
					borderRadius: 10
				}}>
				<span
					style={{
						width: "40px",
						height: "40px",
						borderRadius: 40,
					}}
					className={`fi fi-kw fis`}
				/>
				{translate("kuwait")}
			</div>

			<div 
				onClick={() => setCountry("sa")}
				style={{
					color: "white",
					cursor: "pointer",
					padding: "10px 30px",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: 10,
					borderRadius: 10,
				}}>
				<span
					style={{
						width: "40px",
						height: "40px",
						borderRadius: 40,
					}}
					className={`fi fi-sa fis`}
				/>
				{translate("saudi")}
			</div>
		</div>
	)
}