import LandingPageHeadlines from "../components/LandingPageHeadlines";


const HomePage = () => {
	return (
		<>
			<LandingPageHeadlines />
		</>
	)
}

export default HomePage;