import { Divider } from "@mui/material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { Calendar, Event, luxonLocalizer } from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { generatePath } from "react-router-dom";
import { apiCommand } from "../functions/apiCommand";
import { apiQuery, ApiQueryTypes } from "../functions/apiQuery";

const localizer = luxonLocalizer(DateTime);

const CANCELLED_EVENT_COLOR = 'rgba(207, 0, 15, 0.5)'
const CONFIRMED_EVENT_COLOR = 'rgb(0, 200, 83)'
// const MODIFIED_EVENT_COLOR = 'rgba(0, 41, 104, 0.5)'

const SelectedEventDialog: React.FC<{
		eventDialogState: { open: boolean, data?: ApiQueryTypes['list_bookings'][0] }
		handleClose: () => void,
		refreshEvents: () => void
}> = ({eventDialogState, handleClose, refreshEvents}) => {

	const command = apiCommand();

	const cancelBookingOrder = (bookingOrderId: string) => {
		if (bookingOrderId) {
			command({
				command_name: "cancel_booking_order",
				title: "Cancel Booking Order",
				extra_path: `${bookingOrderId}/cancel/`,
				body: () => (
					<>
						<Typography>This action is irreversible!</Typography>
					</>
				),
				onSuccess: () => {
					handleClose();
					refreshEvents();
				}
			})
		}
	}

	const handleModifyBookingOrder = (bookingSessionId: string) => {
		const url = generatePath("/book-a-table/:bookingSessionId", {
		  bookingSessionId: bookingSessionId,
		});
		const link = document.createElement("a");
		link.href = url;
		link.target = "_blank";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};


	const { open, data } = eventDialogState;
	if (!data) {
		return null;
	}
	const {
		id: bookingSessionId,
		status,
		createdAt,
		updatedAt,
		packageName,
		selectedBranch,
		selectedDate,
		selectedTimeSlot,
		selectedTables,
		partySize,
		fullName,
		occasion,
		phoneNumber,
		email,
		optionalDetails,
		paymentLink,
		bookingAddons,
	} = data.bookingSession;

	const bookingOrderId = data.id;
	const isCancelled = data.status === 'CANCELLED_BY_ADMIN'

	const paymentProvider = data.paymentProvider;

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			style={{
				zIndex: 1200
			}}
		>
			<DialogTitle>
				Booking For {fullName}
			</DialogTitle>
			<DialogContent>
				<Grid container
					spacing={2}>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Session ID:
						</Typography>
						<Typography variant="body1">{bookingSessionId}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Status:
						</Typography>
						<Typography variant="body1">{status}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Created At:
						</Typography>
						<Typography variant="body1">{createdAt}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Last Updated At:
						</Typography>
						<Typography variant="body1">{updatedAt}</Typography>
					</Grid>
					<Grid 
						item 
						xs={12}
					>
						<Divider />
					</Grid>
					<Grid 
						item
						xs={12}
						sm={6}
					>
						<Typography variant="subtitle1"
						>
							Payment Provider:
						</Typography>
						<Typography variant="body1">{paymentProvider}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Paid Amount:
						</Typography>
						<Typography variant="body1">
							{data.hesabeCapturedData?.amount ? `${data.hesabeCapturedData.amount} KWD` : 'N/A'}
						</Typography>
					</Grid>
					<Grid 
						item 
						xs={12}
					>
						<Divider />
					</Grid>
					<Grid item
						xs={12}>
						<Typography variant="subtitle1"
						>
							Branch:
						</Typography>
						<Typography variant="body1">{selectedBranch?.name.en}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Date:
						</Typography>
						<Typography variant="body1">{selectedDate}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Time Slot:
						</Typography>
						<Typography variant="body1">
							{selectedTimeSlot?.timeFrom} - {selectedTimeSlot?.timeTo}
						</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Party Size
						</Typography>
						<Typography variant="body1">{partySize}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Selected Tables
						</Typography>
						<Typography variant="body1">{selectedTables.map(x => x.compositeId.id).join(", ")}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Package Name
						</Typography>
						<Typography variant="body1">{packageName || 'N/A'}</Typography>
					</Grid>
					<Grid 
						item 
						xs={12}
					>
						<Divider />
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Full Name:
						</Typography>
						<Typography variant="body1">{fullName}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Occasion:
						</Typography>
						<Typography variant="body1">{occasion}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Phone Number:
						</Typography>
						<Typography variant="body1">{phoneNumber}</Typography>
					</Grid>
					<Grid item
						xs={12}
						sm={6}>
						<Typography variant="subtitle1"
						>
							Email:
						</Typography>
						<Typography variant="body1">{email}</Typography>
					</Grid>
					<Grid item
						xs={12}>
						<Typography variant="subtitle1"
						>
							Optional Details:
						</Typography>
						<Typography variant="body1">{optionalDetails}</Typography>
					</Grid>
					<Grid 
						item 
						xs={12}
					>
						<Divider />
					</Grid>
					{
						bookingAddons.map((addon) => (
							<Grid item
								xs={12}
								key={addon.addon.id}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="right"></TableCell>
											<TableCell>Name</TableCell>
											<TableCell align="right">Quantity</TableCell>
											<TableCell align="right">Price</TableCell>
											<TableCell align="right">Total</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{bookingAddons.map((addon) => (
											<TableRow key={addon.addon.id}>
												<TableCell align="right">
													<img
														style={{
															width: 100,
															height: 100
														}}
														src={`/api/file/${addon.addon.photoUrl}`} />
												</TableCell>
												<TableCell>{addon.addon.name}</TableCell>
												<TableCell align="right">{addon.quantity}</TableCell>
												<TableCell align="right">{addon.addon.price}</TableCell>
												<TableCell align="right">{(parseFloat(addon.addon.price) * addon.quantity).toFixed(3)}</TableCell>

											</TableRow>
										))}
									</TableBody>
								</Table>
							</Grid>
						))}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
				{/* <Button onClick={() => handleModifyBookingOrder(bookingSessionId)}>Modify Booking</Button> */}
				{
					!isCancelled && (
						<Button 
							onClick={() => cancelBookingOrder(bookingOrderId)}>
							Cancel Booking
						</Button>
					)
				}
			</DialogActions>
		</Dialog>
	)
}

const CalendarAdminPage: React.FC = () => {
	const [selectedBranch, setSelectedBranch] = useState<string>('');
	const [eventDialogState, setEventDialogState] = useState<{ open: boolean, data?: ApiQueryTypes['list_bookings'][0] }>({ open: false });
	const [branches, reloadBranches] = apiQuery("list_branches", "/api/branch/");
	const [bookings, reloadBookings] = apiQuery("list_bookings", `/api/admin/booking-order?branch_id=${selectedBranch}`);

	useEffect(() => {
		reloadBookings();
	}, [selectedBranch])

	const handleCloseEventDialog = () => setEventDialogState({ open: false });

	const getCalendarEvents: () => Event[] = () => {
		return (bookings || []).map(x => ({
			title: x.bookingSession.fullName,
			start: DateTime.fromFormat(`${x.bookingSession.selectedDate} ${x.bookingSession.selectedTimeSlot?.timeFrom}`, 'dd-MM-yyyy HH:mm').toJSDate(),
			end: DateTime.fromFormat(`${x.bookingSession.selectedDate} ${x.bookingSession.selectedTimeSlot?.timeTo}`, 'dd-MM-yyyy HH:mm').toJSDate(),
			resource: x
		}))
	}

	return (
		<div>
			<SelectedEventDialog
				eventDialogState={eventDialogState}
				handleClose={handleCloseEventDialog}
				refreshEvents={reloadBookings}
			/>
			<FormControl
				fullWidth
				style={{
					marginBottom: 10
				}}
			>
				<InputLabel>Branch</InputLabel>
				<Select
					value={selectedBranch}
					label="Branch"
					onChange={(e) => setSelectedBranch(e.target.value)}
				>
					{branches?.map((branch) => <MenuItem
						key={branch.id}
						value={branch.id}>{branch.name_en}</MenuItem>)}
				</Select>
			</FormControl>
			{
				selectedBranch !== '' && bookings === undefined
					? <CircularProgress />
					: (
						<div
							style={{
								height: "800px",
								width: "100%",
								backgroundColor: "white",
							}}
						>
							<Calendar
								localizer={localizer}
								defaultView="month"
								events={getCalendarEvents()}
								popup={true}
								onSelectEvent={(e) => setEventDialogState({
									open: true,
									data: e.resource
								})}
								eventPropGetter={(event) => {
									const bookingOrder: ApiQueryTypes['list_bookings'][0] = event.resource;

									if (bookingOrder.bookingSession.status === 'CANCELLED_BY_ADMIN') {
										return {
											style: {
												backgroundColor: CANCELLED_EVENT_COLOR
											}
										}
									}

									if (bookingOrder.bookingSession.status === 'COMPLETED_SESSION') {
										return {
											style: {
												backgroundColor: CONFIRMED_EVENT_COLOR
											}
										}
									}

									return {}
								}}
							>
							</Calendar>
						</div>
					)
			}
		</div>
	)
};

export default CalendarAdminPage;