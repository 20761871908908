import axios from "axios";

export type Branch = {
  name: string;
  floors: Array<FloorPlan>;
};

export type FloorPlan = {
  name: string;
  color: string;
  gridArea: string;
  tables: Array<TableEntry>;
  zones: Array<ZoneEntry>;
};

type TableEntry = {
  id: string;
  name: string;
  chairs: Array<"bottom" | "top" | "left" | "right">;
};

export type ZoneEntry = {
  name: string;
  tableIds: string[];
  peopleRange: [number, number];
  tablesRange: [number, number];
  price?: string;
};


function getBranchFloorPlans(): Promise<Array<Branch>> {
	return axios.get<{ branches: Branch[] }>(`/api/floor-plans/`).then((x) => {
		return x.data.branches;
	});
}


export { getBranchFloorPlans };
