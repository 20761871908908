import React from "react";
import { darkPrimaryColor } from "../constants/colors";


const ThreeFLink: React.FC<{ href: string, style?: { color?: "white", direction?: "ltr" | "rtl" }, children: React.ReactNode }> = ({ style, children, href }) => {
	return <a href={href}
		style={{
			textDecoration: "none",
			color: style?.color || darkPrimaryColor,
			direction: style?.direction
		}}>{children}</a>
}

export default ThreeFLink;