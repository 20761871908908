import { Helmet } from "react-helmet";
import { useLanguage } from "../hooks/useTranslate";
import {
	Switch,
	Route,
	useLocation,
	Redirect
} from "react-router-dom";
import React from "react";
import ROUTES from "../constants/routes";
import MainDrawer from "../drawers/MainDrawer";
import { useEffect } from "react";
import NotFoundPage from "../pages/NotFound";
import AdminLayout from "../layouts/AdminLayout";
import CustomerLayout from "../layouts/CustomerLayout";
import CommandDialog from "../components/CommandDialog";
import { useCommandDialogContext } from "../context/CommandDialogContext";
import { CountryPicker } from "../components/CountryPicker";
import { useCountry } from "../hooks/useCountry";
import { useToken } from "../hooks/useToken";



const App: React.FC = ({ children }) => {
	const [token] = useToken();
	const [lang] = useLanguage();
	const [country] = useCountry();
	const langToDir = {
		"en": "ltr",
		"ar": "rtl"
	} as const

	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			left: 0,
			top: 0,
			behavior: "smooth"
		});
	}, [pathname]);

	if (country === undefined) {
		return <CountryPicker />
	} else {
		return <>
			<Helmet htmlAttributes={{ dir: langToDir[lang] }} />
			<MainDrawer />
			<CommandDialog />
			<Switch>
				{
					Object
						.values(ROUTES)
						.map(route => {
							const Page = route.component;

							if (route.layout === 'admin') {
								return (
									<Route
										key={route.to}
										exact
										path={route.to}
										render={() =>
											token !== null ? (
												<AdminLayout>
													<Page />
												</AdminLayout>
											): (
												<Redirect to="/admin/login" />
											)
										}
							  />
								)
							} else if (route.layout === 'customer-facing') {
								return (
									<Route
										key={route.to}
										exact
										path={route.to}
										render={() => {
											return (
												<CustomerLayout>
													<Page />
												</CustomerLayout>
											)
										}} />
								)
							} else {
								return (<Route
									key={route.to}
									exact
									path={route.to}
									render={() => {
										return (
												
											<Page />

										)
									}} />
								)
							}
						})
				}
				<CustomerLayout>
					<Route 
						path="*"
						component={NotFoundPage}
					/>
				</CustomerLayout>
			</Switch>
		</>;
	}
}

export default App;