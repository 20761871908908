import React, { useEffect, useState } from 'react';
import {
	Button,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { apiQuery, ApiQueryTypes } from '../functions/apiQuery';
import { apiCommand } from '../functions/apiCommand';

const PackageManagementPage: React.FC = () => {
	const [packages, reloadPackages] = apiQuery("list_packages", "/api/admin/zone/");
	const command = apiCommand();

	const handleUpdatePackage = (pkg: ApiQueryTypes['list_packages'][0]) => {
		command({
			title: "Update Package",
			command_name: "update_package",
			mode: 'multipart',
			extra_path: pkg.id,
			initialState: {
				name: pkg.name,
				price: `${pkg.price}`,
				comments: pkg.comments || '',
			},
			body: (state, setState) => (
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: 10
				}}>
					<TextField
						fullWidth
						
						label="Name"
						value={state.name || undefined}
						onChange={(e) => setState({ name: e.target.value })}
					/>
					<TextField
						fullWidth
						
						label="Price"
						value={state.price || undefined}
						onChange={(e) => setState({ price: e.target.value })}
					/>
					<TextField
						fullWidth
						
						label="Comments"
						value={state.comments || undefined}
						onChange={(e) => setState({ comments: e.target.value })}
					/>
					<div style={{
						display: "flex",
						flexDirection: "column",
						border: '2px gray dashed',
						padding: 10,
						gap: 5
					}}>
						<Button
							variant="contained"
							component="label"
							color="primary"
							startIcon={<CloudUploadIcon />}
						>
							Upload File
							<input
								type="file"
								style={{ display: "none" }}
								onChange={(e) => {
									setState({photo: (e.target.files || [])[0] || undefined})
								}}
							/>
						</Button>
						<Typography variant="caption">
							<a href={state.photo ? URL.createObjectURL(state.photo) : '#'}
								download={state.photo?.name}>
								{state.photo?.name || ''}
							</a>
						</Typography>
					</div>
				</div>
			),
			onSuccess: () => reloadPackages()
		})
	}

	if (!packages) {
		return <CircularProgress />;
	}

	return (
		<div style={{ display: 'flex',
			flexDirection: 'column',
			gap: 10 }}>
			<div style={{ display: 'flex',
				justifyContent: 'space-between' }}>
				<Typography 
					variant="h6"
					fontWeight="bold"
				>
					Package Management
				</Typography>
			</div>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Branch</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Party Size (min - max)</TableCell>
							<TableCell>No. of allowed selected tables (min - max)</TableCell>
							<TableCell>Tables</TableCell>
							<TableCell>Price</TableCell>
							<TableCell>Comments</TableCell>
							<TableCell>Photo</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{packages.map((pkg) => (
							<TableRow key={pkg.id}>
								<TableCell>{pkg.branch.name.en}</TableCell>
								<TableCell>{pkg.name}</TableCell>
								<TableCell>{pkg.peopleRangeMin} - {pkg.peopleRangeMax}</TableCell>
								<TableCell>{pkg.tablesRangeMin} - {pkg.tablesRangeMax}</TableCell>
								<TableCell>{pkg.zoneTables.map(x => x.tableId).join(", ")}</TableCell>
								<TableCell>{pkg.price}</TableCell>
								<TableCell style={{
									minWidth: 250,
									maxWidth: 250,
									wordWrap: "break-word"
								}}>
									{pkg.comments || 'N/A'}
								</TableCell>
								<TableCell>
									{
										pkg.photoUrl 
											? (
												<img
													style={{ width: 100,
														height: 100 }}
													src={`/api/file/${pkg.photoUrl}`}
													alt={pkg.name}
												/>
											) 
											: (<span>N/A</span>)
									}
									
								</TableCell>
								<TableCell>
									<div style={{ display: 'flex',
										flexDirection: 'row',
										gap: 10 }}>
										<Button
											variant="contained"
											color="primary"
											onClick={() => handleUpdatePackage(pkg)}
										>
											Update
										</Button>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default PackageManagementPage;
