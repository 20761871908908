import { createTheme, Theme } from "@mui/material";
import { darkPrimaryColor } from "./colors";


const makeTheme: (dir: "rtl" | "ltr") => Theme = (dir) => {
	const theme = createTheme({
		direction: dir,
		palette: {
			primary: {
				main: darkPrimaryColor
			},
		},
		typography: {
			fontFamily: [
				'Cairo',
				'sans-serif'
			].join(", ")
		}
	})

	return theme;
}

export {makeTheme}