import Footer from "../components/Footer";
import LandingHeading from "../components/LandingHeading";
import PageHeading from "../components/PageHeading";
import { backgroundGray, offWhite } from "../constants/colors";
import { translate } from "../hooks/useTranslate";
import Banner from "../images/banner/banner-1.jpg"

import AbousSectionOneImage from "../images/about-section-one.png"
import AbousSectionTwoImage from "../images/about-section-two.png"
const AboutUsPage = () => {
	return (
		<>
			<PageHeading
				title={translate("about_us")}
				backgroundImage={Banner}
			/>
			<div style={{ backgroundColor: backgroundGray,
				padding: "50px 0px",
				display: "flex",
				flexDirection: "column",
				justifyContent: 'center',
				alignItems: "center" }}>
				<LandingHeading
					title={translate("about_us")}
					subtitle={translate("300f_smokehouse")}
				/>
				<div style={{ display: "flex",
					flexDirection: "row",
					width: "100%",
					margin: "50px 0px",
					flexWrap: "wrap",
					justifyContent: 'center' }}>
					<div style={{ display: "flex",
						flexDirection: "column",
						flexBasis: "600px",
						padding: "20px 50px" }}>
						<span style={{ fontFamily: "Tangerine, Cairo",
							color: offWhite,
							fontSize: 70 }}>{translate("the_history")}</span>
						<span style={{ color: offWhite,
							fontSize: 20 }}>{translate("the_history_paragraph_1")}</span>
						<span style={{ color: offWhite,
							fontSize: 20 }}>{translate("the_history_paragraph_2")}</span>
					</div>
					<div style={{ flexBasis: "30%",
						flexShrink: 1 }}>
						<img src={AbousSectionOneImage}
							style={{ minWidth: 400,
								width: "100%" }}
							alt="About Us" />
					</div>
				</div>

				<div style={{ display: "flex",
					flexDirection: "row",
					width: "100%",
					margin: "50px 0px",
					flexWrap: "wrap",
					justifyContent: 'center', }}>
					<div style={{ flexBasis: "30%",
						flexShrink: 1 }}>
						<img src={AbousSectionTwoImage}
							style={{ minWidth: 400,
								width: "100%" }}
							alt="Our Mission" />
					</div>
					<div style={{ display: "flex",
						flexDirection: "column",
						flexBasis: "600px",
						padding: "20px 50px" }}>
						<span style={{ fontFamily: "Tangerine, Cairo",
							color: offWhite,
							fontSize: 70 }}>{translate("our_mission")}</span>
						<span style={{ color: offWhite,
							fontSize: 20 }}>{translate("our_mission_paragraph_1")}</span>
						<span style={{ color: offWhite,
							fontSize: 20 }}>{translate("our_mission_paragraph_2")}</span>
						<span style={{ color: offWhite,
							fontSize: 20 }}>{translate("our_mission_paragraph_3")}</span>
					</div>
				</div>


			</div>
		</>
	)
}

export default AboutUsPage;