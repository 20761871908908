import { useWindowWidth } from "@react-hook/window-size/throttled"


const usePlatform: () => "mobile" | "desktop" = () => {
	const width = useWindowWidth();
	if (width <= 1200) {
		return "mobile"
	}
	else return "desktop"
}

export default usePlatform;