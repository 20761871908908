import { Button, CircularProgress, Paper, TextField, Typography } from "@mui/material"
import { generatePath, Link, useRouteMatch } from "react-router-dom";
import ROUTES from "../constants/routes";
import { apiCommand } from "../functions/apiCommand";
import { apiQuery } from "../functions/apiQuery";


const TimeSlotAdminPage: React.FC = ({ }) => {
	const match = useRouteMatch<{ dateSlotId: string }>();
	const dateSlotId = match.params.dateSlotId;

	const [timeSlots, reloadTimeSlots] = apiQuery("list_time_slots", `/api/date-slot/${dateSlotId}/time-slots`);
	const [dateSlot, reloadDateSlot] = apiQuery("get_date_slot", `/api/date-slot/${dateSlotId}`);
	const command = apiCommand();
	
	if (!timeSlots || !dateSlot) {
		return <CircularProgress />;
	}

	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			gap: 10
		}}>
			<div style={{
				display: "flex",
				flexDirection: "row",
				gap: 20
			}}>
				<Typography
					variant="h6"
					fontWeight="bold">
					Time Slots For {dateSlot.dateFrom} - {dateSlot.dateTo}
				</Typography>
				<div>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							command({
								title: "Create Time Slot",
								command_name: "create_time_slot",
								initialState: {
									dateSlotId
								},
								body: (state, setState) => (
									<div style={{
										display: "flex",
										flexDirection: "column",
										gap: 10
									}}>
										<TextField
											fullWidth
											required
											label="Time From"
											helperText="Must be in format HH:mm"
											value={state.timeFrom || ''}
											onChange={(e) => setState({ timeFrom: e.target.value })}
										/>
										<TextField
											fullWidth
											required
											label="Time To"
											value={state.timeTo || ''}
											helperText="Must be in format HH:mm"
											onChange={(e) => setState({ timeTo: e.target.value })}
										/>
									</div>
								),
								onSuccess: () => reloadTimeSlots()
							})
						}}
					>
						Create Time Slot
					</Button>
				</div>
			</div>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: 10
			}}>
				{
					timeSlots.length === 0 && (
						<Typography variant="subtitle1">No time slots are availble under this branch</Typography>
					)
				}
				{
					timeSlots.map(timeSlot => {
						return (
							<Paper
								id={timeSlot.id}
								style={{ padding: 10 }}
							>
								<div style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between"
								}}>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											gap: 5,
											alignItems: "center"
										}}
									>
										<Typography
											variant="subtitle1"
											style={{ display: "inline" }}
										>
											Time From:
										</Typography>
										<Typography
											fontWeight="bold"
											variant="subtitle2"
											style={{ display: "inline" }}
										>

											{timeSlot.timeFrom}
										</Typography>
										<Typography
											variant="subtitle1"
											style={{ display: "inline" }}
										>
											To:
										</Typography>
										<Typography
											fontWeight="bold"
											variant="subtitle2"
											style={{ display: "inline" }}
										>
											{timeSlot.timeTo}
										</Typography>
									</div>
									<div style={{
										gap: 10,
										display: "flex",
										alignItems: "center"
									}}>
										<Typography variant="caption">Time Slot ID: {timeSlot.id}</Typography>
										<Button
											variant="contained"
											color="error"
											onClick={() => {
												command({
													command_name: "delete_time_slot",
													title: "Delete Time Slot",
													extra_path: `${timeSlot.id}`,
													body: () => (
														<>
															<Typography
																variant="subtitle1"
																style={{ display: "inline" }}
															>
																From:
															</Typography>
															<Typography
																fontWeight="bold"
																variant="subtitle2"
																style={{ display: "inline" }}
															>

																{timeSlot.timeFrom}
															</Typography>
															<Typography
																variant="subtitle1"
																style={{ display: "inline" }}
															>
																To:
															</Typography>
															<Typography
																fontWeight="bold"
																variant="subtitle2"
																style={{ display: "inline" }}
															>
																{timeSlot.timeTo}
															</Typography>
														</>
													),
													onSuccess: () => reloadTimeSlots()
												})
											}}
										>
											Delete Time Slot
										</Button>
										<Button
											variant="contained"
											color="success"
											onClick={() => {
												command({
													title: "Update Time Slot",
													command_name: "update_time_slot",
													initialState: {
														timeFrom: timeSlot.timeFrom,
														timeTo: timeSlot.timeTo
													},
													extra_path: `${timeSlot.id}`,
													body: (state, setState) => (
														<div style={{
															display: "flex",
															flexDirection: "column",
															gap: 10
														}}>
															<TextField
																fullWidth
																required
																label="Time From"
																helperText="Must be in format HH:mm"
																value={state.timeFrom || ''}
																onChange={(e) => setState({ timeFrom: e.target.value })}
															/>
															<TextField
																fullWidth
																required
																label="Time To"
																value={state.timeTo || ''}
																helperText="Must be in format HH:mm"
																onChange={(e) => setState({ timeTo: e.target.value })}
															/>
														</div>
													),
													onSuccess: () => reloadTimeSlots()
												})
											}}
										>
											Update Date Slot
										</Button>
									</div>
								</div>
							</Paper>
						)
					})
				}
			</div>
		</div>)
}

export default TimeSlotAdminPage;