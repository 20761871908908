import PageHeading from "../components/PageHeading";
import { lightPrimaryColor } from "../constants/colors";
import { translate } from "../hooks/useTranslate";
import Banner from "../images/banner/banner-1.jpg"

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ROUTES from "../constants/routes";
const NotFoundPage = () => {
	return (
		<>
			<PageHeading
				title={translate("404_not_found")}
				children={
					<div style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: 10
					}}>
						<Typography color="white">404: The page you are looking for is not found</Typography>
						<Typography color="white">If you suspect this is an error, kindly
							<Link
								style={{
									textDecoration: "none",
									color: lightPrimaryColor
								}}
								to={ROUTES.CONTACT_US.to}
							>
								{' '}Contact Us
							</Link> 
						</Typography>
					</div>
				}
				backgroundImage={Banner}
			/>
		</>
	)
}

export default NotFoundPage;