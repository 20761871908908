import { useEffect, useState } from "react";

interface HesabeCapturedData {
  id: string;
  status: boolean;
  code: number;
  message: string;
  resultCode: string;
  amount: string;
  paymentToken: string;
  paymentId: string;
  paidOn: string;
  orderReferenceNumber: string;
  auth: string;
  transactionId: string;
  bankReferenceId: string;
  administrativeCharge: string;
  method: number;
}

interface Name {
  id: string;
  en: string;
  ar: string;
}

interface SelectedBranch {
  id: string;
  name: Name;
}

interface SelectedTimeSlot {
  id: string;
  timeFrom: string;
  timeTo: string;
}

interface BookingSession {
  id: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  selectedBranch?: SelectedBranch;
  selectedDate?: string;
  selectedTimeSlot?: SelectedTimeSlot;
  selectedTables: Array<{
    compositeId: {
      id: string;
      bookingSessionId: string;
    };
  }>;
  partySize?: number;
  fullName?: string;
  occasion?: string;
  phoneNumber?: string;
  email?: string;
  optionalDetails?: string;
  packageName?: string;
  paymentLink?: string;
  bookingAddons: Array<BookingAddon>;
}

interface BookingAddon {
  id: string;
  quantity: number;
  addon: {
    id: string;
    name: string;
    price: string;
    availableQuantity: number;
    photoUrl: string;
    enabled: boolean;
  };
}

export type ApiQueryTypes = {
  list_branches: Array<{
    id: string;
    name_en: string;
    name_ar: string;
  }>;
  list_bookings: Array<{
    id: string;
    status: string;
    paymentProvider: string;
    hesabeCapturedData?: HesabeCapturedData;
    bookingSession: BookingSession;
    orderReferenceNumber: string;
  }>;
  get_branch: {
    name_en: string;
    name_ar: string;
  };
  list_date_slots: Array<{
    id: string;
    dateFrom: string;
    dateTo: string;
  }>;
  list_time_slots: Array<{
    id: string;
    timeFrom: string;
    timeTo: string;
  }>;
  get_date_slot: {
    id: string;
    dateFrom: string;
    dateTo: string;
  };
  get_reserved_tables: {
    tableIds: string[];
  };
  list_addons: Array<{
    id: string;
    name: string;
    price: string;
    availableQuantity: string;
    photoUrl: string;
    enabled: boolean
  }>;
  get_booking_session: {
    id?: string;
    status?: string;
    createdAt?: string;
    updatedAt?: string;
    selectedBranch?: {
      id?: string;
      name?: {
        id?: string;
        en?: string;
        ar?: string;
      };
    };
    selectedDate?: string;
    selectedTimeSlot?: {
      id?: string;
      timeFrom?: string;
      timeTo?: string;
    };
    partySize?: number;
    fullName?: string;
    occasion?: string;
    phoneNumber?: string;
    email?: string;
    optionalDetails?: string;
    paymentLink?: null | string;
    packageName?: null | string;
    selectedTables?: {
      compositeId: {
        id: string;
        bookingSessionId: string;
      };
    }[];
    bookingAddons: Array<{
      id: string;
      addon: {
        id: string;
        name: string;
        price: string;
        availableQuantity: number;
        photoUrl: string;
        enabled: boolean;
      };
      quantity: number;
    }>
  };
  get_booking_session_applicable_zones: Array<{
    id: string;
    name: string;
    peopleRangeMin: number;
    peopleRangeMax: number;
    tablesRangeMin: number;
    tablesRangeMax: number;
    price: number;
    zoneTables: Array<{
      id: string
      tableId: string;
    }>;
    comments: string;
    photoUrl: string;
  }>
  list_packages: Array<{
    id: string;
    name: string;
    peopleRangeMin: number;
    peopleRangeMax: number;
    tablesRangeMin: number;
    tablesRangeMax: number;
    price: number;
    zoneTables: Array<{
      id: string
      tableId: string;
    }>;
    branch: {
      name: {
        en: string
      }
    }
    comments: string;
    photoUrl: string;
  }>
};

type ApiQueryDetailsEntry<T extends keyof ApiQueryTypes> = {
  url: ApiQueryTypes[T];
};

function apiQuery<T extends keyof ApiQueryTypes>(
	name: T,
	url: string
): [ApiQueryTypes[T] | undefined, () => void] {
	const [state, setState] = useState<ApiQueryTypes[T] | undefined>();
	const loadData = () => {
		setState(undefined);
		fetch(url)
			.then((res) => res.json())
			.then(setState);
	};
	useEffect(() => {
		loadData();
	}, []);

	return [state, loadData];
}

export { apiQuery };
