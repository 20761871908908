import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { apiCommand } from "../functions/apiCommand"
import { apiQuery, ApiQueryTypes } from "../functions/apiQuery"

const AddonsManagementPage: React.FC = () => {
	const [addons, reloadAddons] = apiQuery("list_addons", "/api/add-on/")
	const command = apiCommand()

	const handleAddAddon = () => {
		command({
			title: "Add Addon",
			command_name: "add_addon",
			mode: 'multipart',
			body: (state, setState) => (
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: 10
				}}>
					<TextField
						fullWidth
						label="Addon Name"
						value={state.name || ''}
						onChange={(e) => setState({ name: e.target.value })}
					/>
					<TextField
						fullWidth
						label="Quantity"
						value={state.quantity || ''}
						onChange={(e) => setState({ quantity: parseInt(e.target.value, 10) || 0 })}
					/>
					<TextField
						fullWidth
						label="Price"
						value={state.price || ''}
						onChange={(e) => setState({ price: e.target.value })}
					/>
					<div style={{
						display: "flex",
						flexDirection: "column",
						border: '2px gray dashed',
						padding: 10,
						gap: 5
					}}>
						<Button
							variant="contained"
							component="label"
							color="primary"
							startIcon={<CloudUploadIcon />}
						>
							Upload File
							<input
								type="file"
								style={{ display: "none" }}
								onChange={(e) => {
									setState({photo: (e.target.files || [])[0] || undefined})
								}}
							/>
						</Button>
						<Typography variant="caption">
							<a href={state.photo ? URL.createObjectURL(state.photo) : '#'}
								download={state.photo?.name}>
								{state.photo?.name || ''}
							</a>
						</Typography>
					</div>
				</div>
			),
			onSuccess: () => reloadAddons()
		})
	}

	const handleUpdateAddon = (addon: ApiQueryTypes['list_addons'][0]) => {
		command({
			title: "Update Addon",
			command_name: "update_addon",
			mode: 'multipart',
			extra_path: addon.id,
			initialState: {
				name: addon.name,
				quantity: parseInt(addon.availableQuantity, 10),
				price: addon.price,
			},
			body: (state, setState) => (
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: 10
				}}>
					<TextField
						fullWidth
						
						label="Addon Name"
						value={state.name || undefined}
						onChange={(e) => setState({ name: e.target.value })}
					/>
					<TextField
						fullWidth
						
						label="Quantity"
						value={state.quantity || undefined}
						onChange={(e) => setState({ quantity: parseInt(e.target.value, 10) || 0 })}
					/>
					<TextField
						fullWidth
						
						label="Price"
						value={state.price || undefined}
						onChange={(e) => setState({ price: e.target.value })}
					/>
					<div style={{
						display: "flex",
						flexDirection: "column",
						border: '2px gray dashed',
						padding: 10,
						gap: 5
					}}>
						<Button
							variant="contained"
							component="label"
							color="primary"
							startIcon={<CloudUploadIcon />}
						>
							Upload File
							<input
								type="file"
								style={{ display: "none" }}
								onChange={(e) => {
									setState({photo: (e.target.files || [])[0] || undefined})
								}}
							/>
						</Button>
						<Typography variant="caption">
							<a href={state.photo ? URL.createObjectURL(state.photo) : '#'}
								download={state.photo?.name}>
								{state.photo?.name || ''}
							</a>
						</Typography>
					</div>
				</div>
			),
			onSuccess: () => reloadAddons()
		})
	}

	const handleDeleteAddon = (addonId: string) => {
		command({
			title: "Delete Addon",
			command_name: "delete_addon",
			extra_path: addonId,
			onSuccess: () => reloadAddons()
		})
	}

	if (!addons) {
		return <CircularProgress />
	}

	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			gap: 10
		}}>
			<div style={{
				display: "flex",
				justifyContent: "space-between"
			}}>
				<Typography variant="h6"
					fontWeight="bold">Addons Management</Typography>
				<Button variant="contained"
					color="primary"
					onClick={handleAddAddon}>Add Addon</Button>
			</div>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Quantity</TableCell>
						<TableCell>Price</TableCell>
						<TableCell>Photo</TableCell>
						<TableCell>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{addons.map((addon) => (
						<TableRow key={addon.id}>
							<TableCell>{addon.name}</TableCell>
							<TableCell>{addon.availableQuantity}</TableCell>
							<TableCell>{addon.price}</TableCell>
							<TableCell>
								<img 
									style={{
										width: 100,
										height: 100
									}}
									src={`/api/file/${addon.photoUrl}`} 
								/>
							</TableCell>
							<TableCell>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										gap: 10
									}}
								>
									<Button
										variant="contained"
										color="primary"
										onClick={() => handleUpdateAddon(addon)}
									>
									Update
									</Button>
									<Button
										variant="contained"
										onClick={() =>
											handleDeleteAddon(addon.id)
										}
									>
									Delete
									</Button>
								</div>

								
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	);
};

export default AddonsManagementPage;