import { primaryColor } from "../constants/colors";

const Chair: React.FC<{orientation: "h" | "v", gridArea?: string}> = ({orientation, gridArea}) => {
	return (
		<div style={{
			border: "1px solid black",
			borderRadius: 20,
			width: orientation === 'h' ? '100%' : "50%",
			height: orientation === 'h' ? "50%" : '100%',
			alignSelf: "center",
			justifySelf: "center",
			gridArea
		}}>
				
		</div>
	)
}


const Gap: React.FC<{gridArea: string}> = ({gridArea}) => <div style={{gridArea}}/>


const Table: React.FC<{ chairs: Array<"top"| "bottom"| "left"| "right">, id: string, name: string, disabled: boolean, onClick: () => void, selected: boolean, reserved: boolean }> = 
({ 
	chairs, 
	id, 
	name,
	selected,
	disabled,
	reserved,
	onClick
}) => {

	return (
		<div style={{
			display: "grid",
			gridTemplateColumns: "0.5fr 2.2fr 0.5fr",
			gridTemplateRows: "0.5fr 2.2fr 0.5fr",
			gap: "0px 0px",
			height: "100px",
			width: "100px",
			rowGap: "2px",
			columnGap: "2px",
			gridTemplateAreas: `
				"tl-gap t-chairs tr-gap"
				"l-chairs table r-chairs"
				"bl-gap b-chairs br-gap"
			`,
			opacity: (disabled || reserved) ? 0.5 : 1
		}}>
			<Gap gridArea="tl-gap" />
			<Gap gridArea="tr-gap"/>
			<Gap gridArea="bl-gap"/>
			<Gap gridArea="br-gap"/>

			{
				chairs.includes("top") && <Chair 
					gridArea="t-chairs" 
					orientation="h" 
				/>
			}

			{
				chairs.includes("bottom") &&<Chair 
					gridArea="b-chairs" 
					orientation="h" 
				/>
			}
			
			{
				chairs.includes("left") &&<Chair 
					gridArea="l-chairs" 
					orientation="v" 
				/>
			}

			{
				chairs.includes("right") &&<Chair 
					gridArea="r-chairs" 
					orientation="v" 
				/>
			}

			<div style={{
				display: "flex",
				alignItems: "center",
				backgroundColor: selected 
					? "green" 
					: reserved 
						? primaryColor
					 	: "#404259",
				gridArea: "table",
			}}>
				<span
					onClick={() => {
						if (reserved) {
							alert("This table is already reserved")
						}else if (disabled) {
							alert("This table cannot be selected due to restrictions")
						} else {
							onClick();
						}
					}}
					style={{
						cursor: (disabled || reserved) ? undefined : "pointer",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "100%",
						textDecoration: "none"
					}}
				>
					<span style={{ color: "white" }}>{name}</span>
				</span>
			</div>
		
		</div>
	)
};

export default Table;