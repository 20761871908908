import LandingHeading from "../components/LandingHeading";
import PageHeading from "../components/PageHeading";
import { backgroundGray, lightPrimaryColor, offWhite } from "../constants/colors";
import { translate } from "../hooks/useTranslate";
import Banner from "../images/banner/banner-1.jpg"


import { CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import ROUTES from "../constants/routes";
import { useState } from "react";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { range } from "../functions/range";
import { BsFillPatchCheckFill as SuccessIcon } from "react-icons/bs"
import { BsFillPatchExclamationFill as FailIcon } from "react-icons/bs"
import { useEffect } from "react"
import { useMemo } from "react";

type TransactionQuery = {
	transactionStatus: boolean;
	orderReferenceNumber: string;
	referenceNumber: string;
	paymentDate: string;
	paymentMethod: string;
	paymentAmount: string;
	paymentStatus: string;
	email: string;
	phoneNumber: string;
}


const PaymentQueryPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [transactionQuery, setTransactionQuery] = useState<TransactionQuery | undefined>(undefined);
	const width = useWindowWidth();
	const paperWidth = range(1000, 400, 30, 75, width);

	const { search } = useLocation();
	const queryParams = useMemo(() => new URLSearchParams(search), [search]);

	useEffect(() => {
		setIsLoading(true)
		fetch(`/api/payment/query?data=${queryParams.get("order_no")}`)
			.then(res => res.json())
			.then(x => setTransactionQuery(x as TransactionQuery))
			.then(() => setIsLoading(false))
	}, []);
	return (
		<>
			<div style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				backgroundColor: backgroundGray,
				width: "100%",
				height: "auto"
			}}
			>
				<Paper style={{
					display: "flex",
					flexDirection: "column",
					margin: "200px 0px",
					minHeight: "400px",
					alignItems: "center",
					justifyContent: "center",
					padding: 40,
					width: "30%",
					minWidth: `${paperWidth}%`,
					gap: 10
				}}>
					{
						isLoading
							? (
								<>
									<CircularProgress variant="indeterminate" />
								</>
							) : (
								<>
									{
										(() => {
											if (transactionQuery?.transactionStatus) {
												return (
													<>
														<SuccessIcon
															size={100}
															color="green"
														/>
														<Typography
															color="green"
															variant="h4"
														>
															{translate("transaction_succeed")}
														</Typography>
													</>
												)
											} else {
												return (
													<>
														<FailIcon
															size={100}
															color="red"
														/>
														<Typography
															variant="h4"
															color="red"
														>
															{translate("transaction_failed")}
														</Typography>
													</>
												)
											}
										})()
									}

									<Table>
										<TableBody>
											<TableRow>
												<TableCell><Typography fontWeight="bold">{translate("order_id")}</Typography></TableCell>
												<TableCell>{transactionQuery?.orderReferenceNumber}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><Typography fontWeight="bold">{translate("reference_id")}</Typography></TableCell>
												<TableCell>{transactionQuery?.referenceNumber}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><Typography fontWeight="bold">{translate("payment_date")}</Typography></TableCell>
												<TableCell>{transactionQuery?.paymentDate}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><Typography fontWeight="bold">{translate("payment_method")}</Typography></TableCell>
												<TableCell>{transactionQuery?.paymentMethod}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><Typography fontWeight="bold">{translate("payment_amount")}</Typography></TableCell>
												<TableCell>{transactionQuery?.paymentAmount}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><Typography fontWeight="bold">{translate("payment_status")}</Typography></TableCell>
												<TableCell>{transactionQuery?.paymentStatus}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><Typography fontWeight="bold">{translate("phone_number")}</Typography></TableCell>
												<TableCell>{transactionQuery?.phoneNumber}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><Typography fontWeight="bold">{translate("email")}</Typography></TableCell>
												<TableCell>{transactionQuery?.email}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</>
							)

					}


				</Paper>
			</div>
		</>
	)
}

export default PaymentQueryPage;