import { Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Table as MUITable,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography 
} from "@mui/material";
import StoreIcon from '@mui/icons-material/Store';
import EmailIcon from '@mui/icons-material/Email';
import ScheduleIcon from '@mui/icons-material/Schedule';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import CakeIcon from '@mui/icons-material/Cake';
import InsertCommentIcon from '@mui/icons-material/InsertComment';

import PageHeading from "../components/PageHeading";
import { backgroundGray, darkPrimaryColor, primaryColor } from "../constants/colors";
import { translate, useLanguage } from "../hooks/useTranslate";
import Banner from "../images/section-banner/book-table-head-banner.jpg"
import React, { useReducer, useRef, useState } from "react";

import KNETLogo from "../images/kent-payment.jpg"
import CreditNetworkLogo from "../images/visa-payment.jpg"
import { useWindowWidth } from "@react-hook/window-size";
import { range } from "../functions/range";
import { apiQuery, ApiQueryTypes } from "../functions/apiQuery";
import { useEffect } from "react";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { apiCommand } from "../functions/apiCommand";
import Table from "../components/Table";
import { getBranchFloorPlans, FloorPlan, ZoneEntry, Branch } from "../constants/tablesData";
import { withinRange } from "../functions/withinRange";

import RemoveIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/AddCircle';
import { useToken } from "../hooks/useToken";
import { usePrevious } from "../hooks/usePrevious";
import { useParams } from "react-router-dom";
import flatten from "../functions/flatten";

type StepAccordionProps = {
	title: string;
	step: number;
	totalSteps: number;
	isOpen: boolean;
	render: (openNext: () => void) => React.ReactElement;
	setOpenStep: (n: number) => void
}

const isDateBetween = (date: DateTime, startDate: DateTime, endDate: DateTime) => {
	return date.diff(startDate).milliseconds >= 0 && date.diff(endDate).milliseconds <= 0
}

const StepAccordion: React.FC<StepAccordionProps> = ({ title, step, totalSteps, isOpen, setOpenStep, render }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	return (
		<Accordion
			ref={ref}
			style={{ width: "100%" }}
			expanded={isOpen}
			disabled={!isOpen}
		>
			
			<AccordionSummary
				style={{
					backgroundColor: darkPrimaryColor,
				}}
			>
				<div style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					width: "100%"
				}}>
					<Typography
						sx={{
							width: '33%',
							flexShrink: 0,
							color: "white"
						}}>
						{title}
					</Typography>
					<Typography sx={{ color: 'white' }}>{`${translate("step")} ${step}/${totalSteps}`}</Typography>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{render(() => setOpenStep(step + 1))}
			</AccordionDetails>
		</Accordion>
	)
}

const Step1Details: Step<{
	branches: ApiQueryTypes["list_branches"] | undefined
}> = ({ openNext, branches, bookingSessionState, setBookingSessionState, setOpenStep }) => {
	const [lang] = useLanguage();
	const command = apiCommand();

	const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);

	const [dateSlots, setDateSlots] = useState<ApiQueryTypes['list_date_slots'] | null>(null);
	const [timeSlots, setTimeSlots] = useState<ApiQueryTypes['list_time_slots'] | null>(null);

	const selecting_branch = translate("selecting_branch")
	const selecting_date = translate("selecting_date")
	const selecting_time = translate("selecting_time")
	const selecting_people_number = translate("selecting_people_number")

	const {branch, date, timeId, partySize, bookingSessionId, selectedTableIds} = bookingSessionState

	useEffect(() => {
		if (branch) {
			axios
				.get<ApiQueryTypes['list_date_slots']>(`/api/branch/${branch.id}/date-slots`)
				.then(x => setDateSlots(x.data))
				.catch(err => console.error(err))
		}
	}, [branch])

	useEffect(() => {
		if (date && dateSlots) {
			const dateSlot = dateSlots.find(slot => isDateBetween(date, DateTime.fromFormat(slot.dateFrom, 'dd-MM-yyyy'), DateTime.fromFormat(slot.dateTo, 'dd-MM-yyyy')));
			axios
				.get<ApiQueryTypes['list_time_slots']>(`/api/date-slot/${dateSlot?.id}/time-slots`)
				.then(x => setTimeSlots(x.data))
				.catch(err => console.error(err))
		}
	}, [date, dateSlots]);

	const updateBranchCommand = (selectedBranch: typeof branch) => {
		if (selectedBranch) {
			command({
				command_name: "update_booking_session",
				title: selecting_branch,
				autoSubmit: true,
				extra_path: `${bookingSessionId}`,
				initialState: {
					action: "SELECT_BRANCH",
					selectedBranch: selectedBranch?.id
				},
				onSuccess: async () => {
					const applicableTableIds = await findApplicableTableIds(bookingSessionId, selectedTableIds);
					const applicableZones = await findApplicableZones(bookingSessionId, selectedTableIds)
					setBookingSessionState({
						applicableTableIds,
						applicableZones,
						date: null
					});
				}
			})
		}
	}

	const updateDateCommand = (_date: DateTime | null) => {
		if (_date) {
			command({
				command_name: "update_booking_session",
				title: selecting_date,
				autoSubmit: true,
				extra_path: `${bookingSessionId}`,
				initialState: {
					action: "SELECT_DATE",
					selectedDate: _date.toFormat("dd-MM-yyyy")
				},
				onSuccess: () => {
					setBookingSessionState({
						timeId: null
					})
				}
			})
		}
	};

	const updateTimeCommand = (timeId: string | null) => {
		if (timeId) {
			command({
				command_name: "update_booking_session",
				title: selecting_time,
				autoSubmit: true,
				extra_path: `${bookingSessionId}`,
				initialState: {
					action: "SELECT_TIME",
					selectedTime: timeId
				},
			})
		}
	}

	const updatePartySizeCommand = (partySize: string | null) => {
		if (partySize) {
			command({
				command_name: "update_booking_session",
				title: selecting_people_number,
				autoSubmit: true,
				extra_path: `${bookingSessionId}`,
				initialState: {
					action: "SELECT_PARTY_SIZE",
					partySize: Number(partySize)
				},
				onSuccess: async () => {
					const applicableTableIds = await findApplicableTableIds(bookingSessionId, selectedTableIds);
					const applicableZones = await findApplicableZones(bookingSessionId, selectedTableIds)
					setBookingSessionState({
						applicableTableIds,
						applicableZones,
					});
				}
			})
		}
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 20
			}}
		>
			<FormControl fullWidth>
				<InputLabel id="branch">{translate("branch")}</InputLabel>
				<Select
					startAdornment={<StoreIcon />}
					inputProps={{ MenuProps: { disableScrollLock: true } }}
					labelId="branch"
					label="Branch"
					defaultValue={branch?.id || null}
					value={branch?.id || null}
					onChange={(e) => {
						const selectedBranch = branches?.find(x => x.id === e.target.value) || null;
						setBookingSessionState({
							branch: selectedBranch,
							date: null,
							timeId: null,
							selectedTableIds: []
						})
						setDateSlots(null)
						updateBranchCommand(selectedBranch)
						setOpenStep(1)
					}}
				>
					{
						branches?.map(branch => (
							<MenuItem value={branch.id}>{lang === "en" ? branch.name_en : branch.name_ar}</MenuItem>
						))
					}
				</Select>
			</FormControl>
			<DatePicker
				label={translate("date")}
				open={isDatePickerOpen}
				onOpen={() => setDatePickerOpen(true)}
				onClose={() => setDatePickerOpen(false)}
				value={date}
				onChange={(newValue) => {
					setBookingSessionState({
						date: newValue,
						timeId: null,
						selectedTableIds: []
					})
					setTimeSlots(null);
					setOpenStep(1)
				}}
				inputFormat="dd/MM/yyyy"
				disablePast
				onAccept={(date) => updateDateCommand(date)}
				shouldDisableDate={(date) => {
					return (dateSlots || []).every(dateSlot => {
						const startDate = DateTime.fromFormat(dateSlot.dateFrom, 'dd-MM-yyyy')
						const endDate = DateTime.fromFormat(dateSlot.dateTo, 'dd-MM-yyyy')

						return !(isDateBetween(date, startDate, endDate))
					})

				}}
				renderInput={(params) =>
					<TextField
						{...params}
						onClick={() => setDatePickerOpen(true)}
					/>

				}
			/>
			<FormControl fullWidth>
				<InputLabel id="time">{translate("time")}</InputLabel>
				<Select
					startAdornment={<ScheduleIcon />}
					inputProps={{ MenuProps: { disableScrollLock: true } }}
					labelId="time"
					label="Time"
					value={timeId}
					onChange={e => {
						setBookingSessionState({
							timeId: e.target.value,
							selectedTableIds: []
						});
						updateTimeCommand(e.target.value);
						setOpenStep(1)
					}
					}
				>
					{timeSlots?.map(timeSlot => (
						<MenuItem value={timeSlot.id}>{`${timeSlot.timeFrom} - ${timeSlot.timeTo}`}</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl fullWidth>
				<InputLabel id="party_size">{translate("no_of_people")}</InputLabel>
				<Select
					startAdornment={<GroupsIcon />}
					inputProps={{ MenuProps: { disableScrollLock: true } }}
					labelId="party_size"
					label="Number of people"
					value={partySize}
					onChange={e => {
						setBookingSessionState({
							partySize: e.target.value,
							selectedTableIds: []
						});
						updatePartySizeCommand(e.target.value);
						setOpenStep(1)
					}}
				>
					{
						Array(12)
							.fill(0)
							.map((x, index) => index + 1)
							.map(value => (
								<MenuItem value={value}>{value}</MenuItem>
							))
					}

				</Select>
			</FormControl>
			<Button
				variant="contained"
				onClick={() => openNext()}
			>
				{translate("next")}
			</Button>
		</div>
	)
}

const Step2Details: Step<{
	selectedBranchFloors: Array<FloorPlan>
}> = ({
	openNext,
	setOpenStep,
	selectedBranchFloors,
	bookingSessionState,
	setBookingSessionState
}) => {

	const {selectedTableIds, reservedTableIds, applicableZones, applicableTableIds, bookingSessionId} = bookingSessionState;
	const [errors, setErrors] = useState<string[]>([]);

	useEffect(() => {
		const zone = applicableZones[0]
		let newErrors: string[] = []
		if (zone && selectedTableIds.length !== 0) {
			const min = zone.tablesRangeMin
			const max = zone.tablesRangeMax

			if (!withinRange(selectedTableIds.length, min, max)) {
				newErrors = [...newErrors, `You must select a minimum of ${min} tables and maximum of ${max} tables`]
			}

			if (applicableZones.length > 1) {
				newErrors = [...newErrors, `You must select more tables`]
			}
		}

		if (selectedTableIds.length === 0) {
			newErrors = [...newErrors, `You must select at least one table`]
		}

		setErrors(newErrors)
	}, [selectedTableIds, applicableZones])

	const command = apiCommand();
	const title = translate("reserving_tables");

	const handleSubmit = () => {
		command({
			title,
			command_name: "update_booking_session",
			initialState: {
				action: "SELECT_TABLES",
				selectedTables: selectedTableIds
			},
			extra_path: `${bookingSessionId}`,
			autoSubmit: true,
			onSuccess: () => openNext()
		})
	}

	if (reservedTableIds === null) {
		return <div style={{
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			gap: 10
		}}>
			<Typography>{translate("loading_tables")}</Typography>
			<CircularProgress />
		</div>
	}

	return (
		<div style={{
			display: "flex",
			flexDirection: "column"
		}}>
			{
				selectedBranchFloors.length === 0 ? (
					<Typography>No branch is selected. Please select a branch before picking the tables</Typography>
				) :

					(
						<>
							<div style={{
								overflow: "scroll",
								display: "flex",
								flexDirection: "column",
								gap: 10,
							}}>
								{
									selectedBranchFloors.map((plan) => (
										<div style={{
											display: "grid",
											gridAutoColumns: "1fr",
											gridAutoRows: "1fr",
											backgroundColor: plan.color,
											gridTemplateAreas: plan.gridArea,
											gap: 10,
											border: "1px solid black",
											padding: 20,
											width: "min-content"
										}}>
											<h2 style={{
												gridArea: "name",
												textAlign: "center"
											}}>
												{plan.name}
											</h2>
											{
												plan.tables.map(table =>
													<div style={{
														gridArea: table.id,
														justifySelf: "center",
														alignSelf: "center"
													}}>
														<Table
															id={table.id}
															name={table.name}
															chairs={table.chairs}
															reserved={reservedTableIds?.includes(table.id) || false}
															selected={selectedTableIds.includes(table.id)}
															disabled={!applicableTableIds.includes(table.id)}
															onClick={() => {
																if (selectedTableIds.includes(table.id)) {
																	setBookingSessionState({
																		selectedTableIds: selectedTableIds.filter(x => x !== table.id)
																	})
																} else {
																	setBookingSessionState({
																		selectedTableIds: [...selectedTableIds, table.id]
																	})
																}
																setOpenStep(2)
															}}

														/>
													</div>
												)
											}
										</div>
									))
								}
							</div>
							{
								errors.map(error => (
									<Typography
										color={primaryColor}
										variant="h6"
									>
										{error}
									</Typography>
								))
							}
							<Button
								variant="contained"
								disabled={
									errors.length !== 0
										|| selectedTableIds.length === 0
										|| applicableZones.length !== 1
								}
								onClick={() => handleSubmit()}
							>
								{translate("next")}
							</Button>
						</>
					)
			}

		</div>
	);
}

const Step3Details: Step<{}> = ({ 
	openNext, 
	setOpenStep,
	bookingSessionState, 
	setBookingSessionState 
}) => {

	const {fullName, email, occasion, phoneNumber, optionalDetails, bookingSessionId} = bookingSessionState;

	const command = apiCommand();
	const title = translate("submit_personal_details");

	const handleSubmit = () => {
		command({
			command_name: "update_booking_session",
			initialState: {
				action: "ENTER_PERSONAL_DETAILS",
				fullName,
				email,
				occasion,
				phoneNumber,
				optionalDetails
			},
			extra_path: `${bookingSessionId}`,
			title,
			autoSubmit: true,
			onSuccess: () => openNext()
		})
	}
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 20
			}}
		>
			<TextField
				InputProps={{
					startAdornment: <PersonIcon />
				}}
				value={fullName}
				onChange={(e) => {
					setBookingSessionState({
						fullName: e.target.value
					})
					setOpenStep(3)
				}}
				label={translate("full_name")}
				color="primary"
			/>
			<TextField
				InputProps={{
					startAdornment: <CakeIcon />
				}}
				value={occasion}
				onChange={(e) => {
					setBookingSessionState({
						occasion: e.target.value
					})
					setOpenStep(3)
				}}
				label={translate("occasion")}
				color="primary"
			/>
			<TextField
				InputProps={{
					startAdornment: <EmailIcon />
				}}
				value={email}
				onChange={(e) => {
					setBookingSessionState({
						email: e.target.value
					})
					setOpenStep(3)
				}}
				label={translate("email")}
				color="primary"
			/>
			<TextField
				InputProps={{
					startAdornment: <LocalPhoneIcon />
				}}
				value={phoneNumber}
				onChange={(e) => {
					setBookingSessionState({
						phoneNumber: e.target.value
					})
					setOpenStep(3)
				}}
				label={translate("phone_number")}
				color="primary"
			/>
			<Typography color="primary">{translate("optional_details")}</Typography>
			<Typography
				variant="subtitle2"
				color="black"
			>
				{translate("optioanl_details_help_text")}
			</Typography>
			<TextField
				InputProps={{
					startAdornment: <InsertCommentIcon />
				}}
				value={optionalDetails}
				onChange={(e) => {
					setBookingSessionState({
						optionalDetails: e.target.value
					})
					setOpenStep(3)
				}}
				multiline
				rows={3}
				color="primary"
			/>
			<Button
				variant="contained"
				onClick={() => handleSubmit()}
			>
				{translate("next")}
			</Button>
		</div>
	)
}


interface Addon {
	id: string;
	name: string;
	price: string;
	availableQuantity: string;
	photoUrl: string;
	enabled: boolean;
}

interface SelectedAddon extends Addon {
	quantity: number;
}


const Step4Details: Step<{
	openNext: () => void;
}> = ({ openNext, bookingSessionState, setBookingSessionState }) => {
	const command = apiCommand();
	const title = translate("submit_addons");

	const [addonsData] = apiQuery("list_addons", "/api/add-on/")

	const {bookingSessionId, selectedAddons} = bookingSessionState;

	const displayAddons = (addonsData || []).filter(x => parseInt(x.availableQuantity, 10) !== 0);

	useEffect(() => {
		if (addonsData) {
			setBookingSessionState({
				selectedAddons: addonsData.reduce((acc, addon) => {
					acc[addon.id] = {
						...addon,
						quantity: selectedAddons[addon.id]?.quantity || 0
					};
					return acc;
				}, {} as Record<string, SelectedAddon>)
			})
		}
	}, [addonsData]);

	const adjustQuantity = (addonId: string, amount: number) => {
		const newSelectedAddons = {...selectedAddons};
		const selectedAddon = newSelectedAddons[addonId];
		if (selectedAddon) {
			const newQuantity = selectedAddon.quantity + amount;
			if (newQuantity >= 0 && newQuantity <= parseInt(selectedAddon.availableQuantity, 10)) {
				newSelectedAddons[addonId] = {
					...selectedAddon,
					quantity: newQuantity,
				};
			}
		}
		setBookingSessionState({
			selectedAddons: newSelectedAddons
		})
	};

	const handleSubmit = () => {
		const selectedAddonsQuantity = (Object.values(selectedAddons).filter(
			(addon) => addon.quantity > 0
		) as SelectedAddon[]).map(x => ({
			id: x.id,
			quantity: `${x.quantity}`
		}));

		command({
			title,
			command_name: "update_booking_session",
			initialState: {
				action: "SELECT_ADD_ONS",
				selectedAddonsQuantity
			},
			extra_path: `${bookingSessionId}`,
			autoSubmit: true,
			onSuccess: () => openNext()
		})
	};

	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			gap: 20
		}}
		>
			{
				displayAddons.length === 0 ? (
					<Typography variant="subtitle1">{translate("no_addons_available")}</Typography>
				) : (
					<MUITable>
						<TableHead>
							<TableRow>
								<TableCell>{translate("name")}</TableCell>
								<TableCell>{translate("price")}</TableCell>
								<TableCell>{translate("photo")}</TableCell>
								<TableCell>{translate("quantity")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{displayAddons.map((addon) => {
								const selectedAddon = selectedAddons[addon.id];
								return (
									<TableRow key={addon.id}>

										<TableCell>{addon.name}</TableCell>
										<TableCell>{addon.price}</TableCell>
										<TableCell>
											<img
												src={`/api/file/${addon.photoUrl}`}
												alt={addon.name}
												style={{ height: 50 }}
											/>
										</TableCell>
										<TableCell>
											<IconButton
												onClick={() => adjustQuantity(addon.id, -1)}
												disabled={!selectedAddon || selectedAddon.quantity === 0 || !selectedAddon.enabled}
											>
												<RemoveIcon />
											</IconButton>
											<span>{selectedAddon ? selectedAddon.quantity : 0}</span>
											<IconButton
												onClick={() => adjustQuantity(addon.id, 1)}
												disabled={!selectedAddon || !selectedAddon.enabled}
											>
												<AddIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</MUITable>
				)
			}
			<Button
				variant="contained"
				onClick={handleSubmit}
			>
				{translate("next")}
			</Button>
		</div>
	);
};

const Step5Details: Step<{}> = ({ bookingSessionState, setBookingSessionState }) => {
	const params = useParams<{bookingSessionId?: string}>()
	const isModifyAction = params.bookingSessionId !== undefined;
	const command = apiCommand();
	const titlePreparing = translate("preparing_payment");
	const titleReserving = translate("reserving_tables");
	const [paymentMethod, setPaymentMethod] = useState<string>('KNET');
	const [token] = useToken();

	const {bookingSessionId, applicableZones, selectedAddons} = bookingSessionState

	const packageName = applicableZones[0]?.name || null;
	const packagePrice = applicableZones[0]?.price
	const packagePhotoUrl = applicableZones[0]?.photoUrl
	const packageComments = applicableZones[0]?.comments;
	const addonsPrice = Object
		.values(selectedAddons)
		.reduce((acc, addon) => acc + (addon.quantity * parseFloat(addon.price)), 0)
		.toFixed(3)


	let dueAmount = ((parseFloat(`${packagePrice}` || '0')) + parseFloat(addonsPrice)).toFixed(3);


	const generateAndredirectToPaymentLink = () => {
		command({
			title: titlePreparing,
			command_name: "update_booking_session",
			autoSubmit: true,
			extra_path: `${bookingSessionId}`,
			initialState: {
				action: "GENERATE_PAYMENT_LINK",
				paymentMethod
			},
			onSuccess: ({ paymentLink }) => {
				if (paymentLink) {
					window.location = paymentLink as any
				}
			}
		})
	}

	const adminReservation = () => {
		command({
			title: titleReserving,
			command_name: "update_booking_session",
			autoSubmit: true,
			extra_path: `${bookingSessionId}`,
			initialState: {
				action: "ADMIN_RESERVE"
			},
			onSuccess: () => {
				setTimeout(() => {
					alert("The booking is completed");
					location.reload()
				}, 100)
			}
		})
	}

	const modifyReservation = () => {
		command({
			title: titleReserving,
			command_name: "update_booking_session",
			autoSubmit: true,
			extra_path: `${bookingSessionId}`,
			initialState: {
				action: "ADMIN_MODIFY"
			},
			onSuccess: () => {
				setTimeout(() => {
					alert("The booking is modified successfully");
				}, 100)
			}
		})
	}

	if (packageName === null) {
		return <Typography
			color="primary"
			variant="h5">
			{`Error determining the applicable package`}
		</Typography >
	}

	return (
		<div style={{
			display: "flex",
			flexDirection: "column"
		}}>
			<FormControl>
				<FormLabel id="payment-method">{translate("payment_method")}</FormLabel>
				<RadioGroup
					aria-labelledby="payment-method"
					defaultValue="KNET"
					value={paymentMethod}
					onChange={e => setPaymentMethod(e.target.value)}
					name="radio-buttons-group"
				>
					<FormControlLabel value="KNET"
						control={<Radio />}
						label={(
							<div style={{
								display: "flex",
								flexDirection: "row",
								gap: 5
							}}
							>
								<img
									src={KNETLogo}
									alt="KNET"
								/>
								{translate("pay_by_knet")}
							</div>
						)}
					/>
					<FormControlLabel value="CREDIT"
						control={<Radio />}
						label={(
							<div style={{
								display: "flex",
								flexDirection: "row",
								gap: 5
							}}
							>
								<img
									src={CreditNetworkLogo}
									alt="Credit Networks"
								/>
								{translate("pay_by_credit_network")}
							</div>
						)}
					/>
				</RadioGroup>
			</FormControl>
			<Divider style={{ margin: "10px 0px" }} />
			<div style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				gap: 10
			}}>
				<div style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					alignItems: "flex-start",
					flexWrap: "wrap",
					flexBasis: "50%",
					gap: 10
				}}>
					<Typography
						color="primary"
						variant="h5">
						{`${translate("package_details")}`}
					</Typography >
					<Typography
						variant="subtitle1">
						{`${translate("package")}: ${packageName}`}
					</Typography >
					<Typography
						variant="subtitle1">
						{`${translate("description")}: ${packageComments || 'N/A'}`}
					</Typography >
					<Typography
						variant="subtitle1">
						{`${translate("package_price")}: ${packagePrice} ${translate("KWD")}`}
					</Typography >
				</div>
				<div style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexBasis: "50%"
				}}>
					<img
						style={{ width: 100,
							height: 100 }}
						src={`/api/file/${packagePhotoUrl}`}
						alt="Package Photo"
					/>
				</div>
			</div>
			<Divider style={{ margin: "10px 0px" }} />
			<div style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-start",
				alignItems: "flex-start",
				flexWrap: "wrap",
				gap: 10
			}}>
				<Typography
					variant="subtitle1">
					{`${translate("addons_price")}: ${addonsPrice} ${translate("KWD")}`}
				</Typography >
			</div>
			<Divider style={{ margin: "10px 0px" }} />
			<div style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-start",
				alignItems: "flex-start",
				flexWrap: "wrap",
				gap: 10
			}}>
				
				<Typography
					color="primary"
					variant="h5">
					{`${translate("total_amount")}: ${dueAmount} ${translate("KWD")}`}
				</Typography >
				<Button 
					variant="contained"
					disabled={isModifyAction}
					onClick={() => generateAndredirectToPaymentLink()}>
					Book Now
				</Button>
				{
					token && (
						<Button 
							variant="contained"
							onClick={() => adminReservation()}
							disabled={isModifyAction}
						>
							Admin Reservation
						</Button>
					)
				}

				{isModifyAction && token &&  (
					<Button 
						variant="contained"
						onClick={() => modifyReservation()}>
						Modify Reservation
					</Button>
				)
				}

			</div>
		</div>
	)
}


const findApplicableZones = async (bookingSessionId: string | null, selectedTableIds: string[]) => {
	if (!bookingSessionId) {
		return []
	};

	const partiallyApplicableZones = await axios
		.get<ApiQueryTypes['get_booking_session_applicable_zones']>(`/api/booking-session/${bookingSessionId}/applicable-zones`)
		.then(x => x.data)

	const applicableZones = partiallyApplicableZones
		.filter(zone => selectedTableIds.length === 0 ? true : zone.zoneTables.flatMap(x => x.tableId).some(tableId => selectedTableIds.includes(tableId)))
	
	return applicableZones;
}

const findApplicableTableIds = async (bookingSessionId: string | null, selectedTableIds: string[]) => {
	const applicableZones = await findApplicableZones(bookingSessionId, selectedTableIds);

	const applicableTableIds =
		applicableZones
			.filter(zone => selectedTableIds.length < zone.tablesRangeMax)
			.map(x => flatten(x.zoneTables.map(x => x.tableId)))
			.reduce((acc, zone) => [...acc, ...zone], [])
			.concat(selectedTableIds)

	return applicableTableIds;
}

type BookingSessionState = {
	bookingSessionId: string | null
	branch: ApiQueryTypes['list_branches'][0] | null;
	partySize: string | null;
	date: DateTime | null;
	timeId: string | null;
	reservedTableIds: string[] | null // selected by other booking sessions
	selectedTableIds: string[], // selected by this current booking session
	applicableTableIds: string[] // allowed to be selected by this current booking session
	applicableZones: ApiQueryTypes['get_booking_session_applicable_zones']
	selectedAddons: Record<string, SelectedAddon>;
	fullName: string;
	occasion: string;
	email: string;
	phoneNumber: string;
	optionalDetails: string;
};

type BookingSessionStateInjector = {
	bookingSessionState: BookingSessionState;
	setBookingSessionState: React.Dispatch<Partial<BookingSessionState>>;
	openNext: () => void,
	setOpenStep: React.Dispatch<number>,
};

type Step<Props> = React.FC<Props & BookingSessionStateInjector>

const bookingSessionInitializer: BookingSessionState = {
	bookingSessionId: null,
	branch: null,
	partySize: null,
	date: null,
	timeId: null,
	reservedTableIds: null,
	selectedTableIds: [],
	applicableTableIds: [],
	applicableZones: [],
	selectedAddons: {},
	fullName: '',
	occasion: '',
	email: '',
	phoneNumber: '',
	optionalDetails: ''
}

const BookATablePage = () => {
	const params = useParams<{bookingSessionId?: string}>()
	const isModifyAction = params.bookingSessionId !== undefined;
	const command = apiCommand();
	const initCommandTitle = translate("initializing_booking_session")

	const [branchFloorPlans, setBranchFloorPlans] = useState<Branch[] | undefined>(undefined);
	const [openStep, setOpenStep] = useState(1);
	const width = useWindowWidth();
	const accordionsWidth = range(1000, 400, 50, 95, width);
	const [branches] = apiQuery("list_branches", "/api/branch/");


	const [bookingSessionState, setBookingSessionState] = useReducer(
		(prev: BookingSessionState, next: Partial<BookingSessionState>) => ({
			...prev,
			...next
		}), 
		bookingSessionInitializer
	)

	const {
		branch, 
		date, 
		timeId, 
		partySize, 
		bookingSessionId, 
		selectedAddons, 
		selectedTableIds,
	} = bookingSessionState;

	const previousSelectedAddons = usePrevious(selectedAddons);

	useEffect(() => {
		if (!isModifyAction && bookingSessionId === null) {
			command({
				title: initCommandTitle,
				command_name: "create_booking_session",
				autoSubmit: true,
				onSuccess: (res) => {
					setBookingSessionState({
						bookingSessionId: res.id
					})
				}
			})
		}
	}, [bookingSessionId, isModifyAction])

	useEffect(() => {
		if (isModifyAction) {
			axios
				.get<ApiQueryTypes['get_booking_session']>(`/api/booking-session/${params.bookingSessionId}/`)
				.then(x => {
					setBookingSessionState({
						bookingSessionId: x.data.id,
						branch: {
							id: x.data.selectedBranch?.id || '',
							name_ar: x.data.selectedBranch?.name?.ar || '',
							name_en: x.data.selectedBranch?.name?.en || '',
						},
						date: x.data.selectedDate ? DateTime.fromFormat(x.data.selectedDate, "dd-MM-yyyy") : null,
						timeId: x.data.selectedTimeSlot?.id,
						partySize: `${x.data.partySize}`,
						selectedTableIds: x.data.selectedTables?.map(x => x.compositeId.id) || [],
						fullName: x.data.fullName,
						occasion: x.data.occasion,
						email: x.data.email,
						phoneNumber: x.data.phoneNumber,
						optionalDetails: x.data.optionalDetails,
						selectedAddons: x.data.bookingAddons.reduce((acc, current) => {
							acc[current.addon.id] = {
								...current.addon,
								availableQuantity: String(current.addon.availableQuantity),
								quantity: current.quantity
							}

							return acc;
						}, {} as Record<string, SelectedAddon>)
					})
				})
				.catch(err => console.error(err))
		}
	}, [isModifyAction])

	useEffect(() => {
		getBranchFloorPlans()
			.then((x) => setBranchFloorPlans(x))
			.catch(console.error)
	}, [])

	useEffect(() => {
		if (
			branch !== null &&
			date !== null &&
			timeId !== null &&
			partySize !== null
		) {
			axios
				.get<ApiQueryTypes['get_reserved_tables']>(`/api/booking-session/${bookingSessionId}/blocked-tables`)
				.then(x => setBookingSessionState({
					reservedTableIds: x.data.tableIds
				}))
				.catch(err => console.error(err))
		} else {
			setBookingSessionState({
				reservedTableIds: null
			})
		}
	}, [
		branch,
		date,
		timeId,
		partySize,
	])

	const updateApplicableZonesAndTables = async () => {
		const applicableTableIds = await findApplicableTableIds(bookingSessionId, selectedTableIds);
		const applicableZones = await findApplicableZones(bookingSessionId, selectedTableIds)
		setBookingSessionState({
			applicableTableIds,
			applicableZones
		});
	}

	useEffect(() => {
		updateApplicableZonesAndTables()
	}, [
		selectedTableIds,
	])

	useEffect(() => {
		if (
			previousSelectedAddons && 
			JSON.stringify(previousSelectedAddons) !== JSON.stringify({}) &&
			JSON.stringify(previousSelectedAddons) !== JSON.stringify(selectedAddons)
		) {
			setOpenStep(4);
		}
	}, [selectedAddons])

	const steps: Omit<StepAccordionProps, "step" | "totalSteps" | "isOpen" | "setOpenStep">[] = [
		{
			title: translate("booking_step1"),
			render: (openNext) =>
				<Step1Details
					openNext={openNext}
					setOpenStep={setOpenStep}
					bookingSessionState={bookingSessionState}
					setBookingSessionState={setBookingSessionState}
					branches={branches}
				/>
		},
		{
			title: translate("booking_step2"),
			render: (openNext) => <>
				<Step2Details
					bookingSessionState={bookingSessionState}
					setBookingSessionState={setBookingSessionState}
					selectedBranchFloors={(branchFloorPlans || []).find(x => x.name === branch?.name_en)?.floors || []}
					openNext={openNext}
					setOpenStep={setOpenStep}
				/>
			</>
		},
		{
			title: translate("booking_step3"),
			render: (openNext) => <>
				<Step3Details
					bookingSessionState={bookingSessionState}
					setBookingSessionState={setBookingSessionState}
					openNext={openNext}
					setOpenStep={setOpenStep}
				/>
			</>
		},
		{
			title: translate("booking_step4"),
			render: (openNext) => <>
				<Step4Details
					bookingSessionState={bookingSessionState}
					setBookingSessionState={setBookingSessionState}
					openNext={openNext}
					setOpenStep={setOpenStep}
				/>
			</>
		},
		{
			title: translate("booking_step5"),
			render: (openNext) => <>
				<Step5Details
					bookingSessionState={bookingSessionState}
					setBookingSessionState={setBookingSessionState}
					openNext={openNext}
					setOpenStep={setOpenStep}
				/>
			</>
		},
	]

	return (
		<>
			<PageHeading
				title={translate("book_a_table")}
				backgroundImage={Banner}
			/>
			<div style={{
				backgroundColor: backgroundGray,
				padding: "50px 0px",
				display: "flex",
				flexDirection: "column",
				justifyContent: 'center',
				alignItems: "center"
			}}>
				<div style={{
					width: `${accordionsWidth}%`,
					display: "flex",
					flexDirection: "column",
					gap: 20
				}}>
					{
						!branchFloorPlans || !branches || !bookingSessionId
							? <Paper style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								gap: 10,
								padding: 20
							}}>
								<CircularProgress />
								<Typography>Loading Session Data</Typography>
							</Paper>
							: steps.map((step, index, elements) => (
								<StepAccordion
									isOpen={openStep >= index + 1}
									setOpenStep={setOpenStep}
									title={step.title}
									step={index + 1}
									totalSteps={elements.length}
									render={step.render}
								/>
							))
					}
				</div>
			</div>
		</>
	)
}

export default BookATablePage;