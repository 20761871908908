

const primaryColor = "rgb(147, 33, 31)";
const darkPrimaryColor = "rgb(170, 2, 0)";
const lightPrimaryColor = "rgb(255, 58, 56)";
const darkGray = "rgb(48, 52, 54)";
const backgroundGray = "rgb(24, 26, 27)";
const offWhite = "rgb(232, 230, 227)";

export {
	primaryColor,
	offWhite,
	darkPrimaryColor,
	lightPrimaryColor,
	darkGray,
	backgroundGray
}