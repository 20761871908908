import React from "react";


const DrawerContext = React.createContext<[boolean, (v: boolean) => void]>([false, (x) => x]);


const useDrawer = () => {
	return React.useContext(DrawerContext);
}

export {DrawerContext, useDrawer};