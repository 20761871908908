import React, { useState } from "react";
import { backgroundGray, darkGray, lightPrimaryColor, offWhite } from "../constants/colors";
import DiscoverOurStoryBanner from "../images/section-banner/discover-story-banner.jpg"
import { FaPlayCircle } from "react-icons/fa"
import { ImInstagram } from "react-icons/im"
import SpecialBanner from "../images/section-banner/smokehouse-special-banner.jpg";
import Link from "./ThreeFLink";
import { translate, useLanguage } from "../hooks/useTranslate";
import { HeaderLink } from "../components/Header";
import LandingHeading from "../components/LandingHeading";
import PageHeading from "./PageHeading";
import Banner from "../images/section-banner/menu-head-banner.jpg";
import ROUTES from "../constants/routes";
import { useCountry } from "../hooks/useCountry";


const SpecialBookings = () => {
	const [lang] = useLanguage();
	const title = lang === "ar" ? translate("bookings") : translate("special")
	const subtitle = lang === "ar" ? translate("special") : translate("bookings")
	return (
		<div style={{ 
			backgroundColor: backgroundGray,
			padding: "50px 0px",
			display: "flex",
			flexDirection: "column",
			justifyContent: 'center',
			alignItems: "center" }}>
			<LandingHeading title={title}
				subtitle={subtitle} />
			<p style={{ fontWeight: 700,
				color: offWhite,
				fontSize: 18,
				margin: 0,
				textAlign: "center" }}>{translate("spoons_welcoming")}</p>
			<p style={{ fontWeight: 400,
				color: offWhite,
				fontSize: 18,
				margin: 0,
				textAlign: "center",
				marginBottom: 80 }}>{translate("create_memories")}</p>
			<HeaderLink outline
				href={ROUTES.BOOK_A_TABLE.to}
				style={{ padding: "6px 40px",
					backgroundColor: lightPrimaryColor }}
			>
				{translate("book_a_table")}
			</HeaderLink>
		</div>
	)
}

const ThreeFSpecials = () => {
	const [lang] = useLanguage();
	const title = lang === "ar" ? translate("our_dishes") : translate("300f")
	const subtitle = lang === "ar" ? translate("specials") : translate("specials")

	return (
		<div style={{ display: "flex",
			flexDirection: "row",
			backgroundImage: `url(${SpecialBanner})`,
			backgroundSize: "cover",
			flexWrap: "wrap" }}>
			<div style={{ flexBasis: "20%" }} />
			<div style={{ flexBasis: "400px",
				padding: "50px 20px",
				display: "flex",
				flexDirection: "column",
				justifyContent: 'center' }}>
				<LandingHeading title={title}
					subtitle={subtitle}
					mode="dark" />
				<ul style={{ listStyleType: "none",
					padding: 0 }}>
					<li style={{ color: darkGray,
						borderBottom: `1px solid ${darkGray}`,
						fontSize: 22 }}>{translate("smoked_chicken")}</li>
					<li style={{ color: darkGray,
						borderBottom: `1px solid ${darkGray}`,
						fontSize: 22 }}>{translate("smoked_chicken_wings")}</li>
					<li style={{ color: darkGray,
						borderBottom: `1px solid ${darkGray}`,
						fontSize: 22 }}>{translate("300f_burger")}</li>
					<li style={{ color: darkGray,
						borderBottom: `1px solid ${darkGray}`,
						fontSize: 22 }}>{translate("pulled_ribs")}</li>
				</ul>

				<div style={{ flexBasis: "100%" }}>
					<HeaderLink outline
						style={{ backgroundColor: lightPrimaryColor }}
						href="#">{translate("view_full_menu")}</HeaderLink>
				</div>


			</div>

			<div style={{ flexBasis: "50%" }} />
		</div>
	)
}

const DiscoverOurStory = () => {
	const [displayVideo, setDisplayVideo] = useState<boolean>(false);
	return (
		<div style={{ backgroundImage: `url(${DiscoverOurStoryBanner})`,
			height: 500 }}>
			<div style={{ display: displayVideo ? "none" : "flex",
				padding: "160px 0px",
				flexDirection: "column",
				alignItems: "center",
				gap: 20 }}>
				<LandingHeading title={translate("discover")}
					subtitle={translate("our_story")} />
				<span style={{ cursor: "pointer" }}
					onClick={() => setDisplayVideo(true)}>
					<FaPlayCircle style={{ fontSize: 75,
						color: lightPrimaryColor }} />
				</span>

			</div>
			<div style={{ display: displayVideo ? "block" : "none",
				height: 500 }}>
				<iframe title="Our Story Youtube"
					src="https://www.youtube.com/embed/LXs6TEomKpw?controls=0"
					style={{ width: "100%",
						height: "100%" }}
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"></iframe>
			</div>
		</div>
	)
}

const SomeFromInsta = () => {
	return (
		<div style={{ backgroundColor: backgroundGray }}>
			<div style={{ display: "flex",
				flexDirection: "column",
				alignItems: "center",
				padding: "50px 0px" }}>
				<LandingHeading title={translate("some_from")}
					subtitle={translate("our_instagram")} />
				<div style={{ margin: "20px 0px",
					display: 'flex',
					flexDirection: "row",
					justifyContent: 'center',
					alignItems: "center",
					gap: 5 }}>
					<ImInstagram style={{ color: offWhite }} />
					<span style={{ color: offWhite,
						fontWeight: 400,
						fontSize: 18 }}>{translate("follow")}{" "}<Link href="https://www.instagram.com/300f.kw/">@300f.kw</Link>{" "}{translate("on_instagram")}</span>
				</div>
			</div>
		</div>
	)
}


const LandingPageHeadlines = () => {
	const [country] = useCountry();

	if (country === 'kw') {
		return (
			<>
				<PageHeading
					backgroundImage={Banner}
					title={translate("new_branch")}
				>
					<span style={{ fontSize: 20 }}>
						<HeaderLink outline
							style={{ padding: "6px 40px" }}
							href={ROUTES.BOOK_A_TABLE.to}>{translate("book_now")}</HeaderLink>
					</span>
				</PageHeading>
				<SpecialBookings />
				<ThreeFSpecials />
				<DiscoverOurStory />
				<SomeFromInsta />
			</>
		)
	} else {
		return (
			<PageHeading
				backgroundImage={Banner}
				title={translate("coming_soon")}
			>
			</PageHeading>
		)
	}
}

export default LandingPageHeadlines;
