import { Button, Paper, CircularProgress, Typography, TextField, Divider } from "@mui/material";
import { generatePath, Link } from "react-router-dom";
import { darkPrimaryColor } from "../constants/colors";
import ROUTES from "../constants/routes";
import { apiCommand } from "../functions/apiCommand";
import { apiQuery } from "../functions/apiQuery";

const BranchAdminPage = () => {
	const [branches, reloadBranches] = apiQuery("list_branches", "/api/branch/");
	const command = apiCommand();


	if (!branches) {
		return <CircularProgress />;
	}

	return (
		<>
			<div style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				marginBottom: 20,
				gap: 20
			}}>
				<Typography variant="h4">Branches</Typography>
				<Button variant="contained"
					onClick={() => {
						command({
							title: "Create Branch",
							command_name: "create_branch",
							body: (state, setState) => (
								<div style={{
									display: "flex",
									flexDirection: "column",
									gap: 10
								}}>
									<TextField
										fullWidth
										required
										label="Name English"
										value={state?.name_en || ''}
										onChange={(e) => setState({ name_en: e.target.value })}
									/>
									<TextField
										fullWidth
										required
										label="Name Arabic"
										value={state?.name_ar || ''}
										onChange={(e) => setState({ name_ar: e.target.value })}
									/>
								</div>
							)
							,
							onSuccess: () => reloadBranches()
						})
					}}>
					Create Branch
				</Button>
			</div>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: 10
			}}>
				{
					branches.length === 0 && (
						<Typography variant="subtitle1">No branches are availble</Typography>
					)
				}
				{
					branches
						?.map(branch => (
							<Paper key={branch.id}
								style={{ padding: 20 }}>
								<div style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: 10,
									justifyContent: "flex-end"
								}}>
									<Typography variant="caption">Branch ID: {branch.id}</Typography>
									<Button
										variant="contained"
										color="error"
										onClick={() => {
											command({
												title: "Delete Branch",
												command_name: "delete_branch",
												extra_path: `${branch.id}`,
												body: () => (
													<div>
														<Typography variant="overline">Branch Details</Typography>
														<Typography
															variant="subtitle1"
														>
															Name english: {branch.name_en}
														</Typography>
														<Typography
															variant="subtitle1"
														>
															Name Arabic: {branch.name_ar}
														</Typography>
														<Typography
															color="error"
															fontWeight="bold"
														>
															This action is irreversible!
														</Typography>
													</div>
												),
												onSuccess: () => reloadBranches()
											})
										}}
									>
										Delete Branch
									</Button>
									<Button
										variant="contained"
										color="success"
										onClick={() => {
											command({
												title: "Update Branch",
												command_name: "update_branch",
												initialState: {
													name_ar: branch.name_ar,
													name_en: branch.name_en
												},
												extra_path: `${branch.id}`,
												body: (state, setState) => (
													<div style={{
														display: "flex",
														flexDirection: "column",
														gap: 10
													}}>
														<TextField
															fullWidth
															required
															label="Name English"
															value={state.name_en || ''}
															onChange={(e) => setState({ name_en: e.target.value })}
														/>
														<TextField
															fullWidth
															required
															label="Name Arabic"
															value={state.name_ar || ''}
															onChange={(e) => setState({ name_ar: e.target.value })}
														/>
													</div>
												),
												onSuccess: () => reloadBranches()
											})
										}}
									>
										Update Branch
									</Button>
								</div>

								<Typography
									variant="h6"
									fontWeight="bold">
									Name English
								</Typography>
								<Typography
									variant="subtitle1"
								>
									{branch.name_en}
								</Typography>
								<Typography
									variant="h6"
									fontWeight="bold">
									Name Arabic
								</Typography>
								<Typography
									variant="subtitle1"
								>
									{branch.name_ar}
								</Typography>
								<Divider style={{ margin: 10 }} />
								<Link to={generatePath(ROUTES.DATE_SLOT_ADMIN.to, {branchId: branch.id})}>Manage Date Slots</Link>
							</Paper>
						))
				}
			</div>
		</>
	)
}

export default BranchAdminPage;