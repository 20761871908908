import { useCommandDialogContext } from "../context/CommandDialogContext";

export type CommandDialogSpec<T extends keyof ApiCommandTypes> = {
	title: string;
	command_name: T;
	mode?: "json" | "multipart"
	formData?: FormData;
	body?: (state: Partial<ApiCommandTypes[T]["body"]>, setState: (data: Partial<ApiCommandTypes[T]["body"]>) => void) => React.ReactNode;
	initialState?: Partial<ApiCommandTypes[T]["body"]>;
	extra_path?: string;
	autoSubmit?: boolean;
	onSuccess?: (result: ApiCommandTypes[T]["response"]) => void;
	actionFun: (body: ApiCommandTypes[T]["body"]) => Promise<Response>;
}

type IssueCommandFunctionType = <T extends keyof ApiCommandTypes>(user_spec: Omit<CommandDialogSpec<T>, "actionFun">) => void;

type ApiCommandTypes = {
	create_branch: {
		body :{
			name_ar: string;
			name_en: string;
		},
		response: undefined;
	},
	delete_branch: {
		body: undefined;
		response: undefined;
	},
	update_branch: {
		body :{
			name_ar: string;
			name_en: string;
		},
		response: undefined;
	},
	create_date_slot: {
		body: {
			branchId: string;
			dateFrom: string;
			dateTo: string;
		},
		response: undefined;
	}
	update_date_slot: {
		body: {
			branchId: string;
			dateFrom: string;
			dateTo: string;
		},
		response: undefined;
	}
	create_time_slot: {
		body: {
			dateSlotId: string;
			timeFrom: string;
			timeTo: string;
		},
		response: undefined;
	},
	update_time_slot: {
		body: {
			timeFrom: string,
			timeTo: string
		},
		response: undefined
	},
	delete_time_slot: {
		body: undefined;
		response: undefined;
	},
	delete_date_slot: {
		body: undefined,
		response: undefined;
	},
	create_booking_session: {
		body: undefined,
		response: {
			id: string;
		};
	},
	update_booking_session: {
		body: {
			action: "SELECT_BRANCH",
			selectedBranch: string;
		} | {
			action: "SELECT_DATE";
			selectedDate: string;
		} | {
			action: "SELECT_TIME";
			selectedTime: string
		} | {
			action: "SELECT_PARTY_SIZE",
			partySize: number
		} | {
			action: "SELECT_TABLES",
			selectedTables: string[]
		} |{
			action: "ENTER_PERSONAL_DETAILS",
			fullName: string;
			occasion: string;
			email: string;
			phoneNumber: string;
			optionalDetails: string;
		} | {
			action: "SELECT_ADD_ONS"
			selectedAddonsQuantity: Array<{
				id: string;
				quantity: string;
			}>
		} | {
			action: "GENERATE_PAYMENT_LINK",
			paymentMethod: string
		} | {
			action: 'ADMIN_RESERVE'
		} | {
			action: 'ADMIN_MODIFY'
		},
		response: {
			id: string;
			paymentLink?: string;
		};
	},
	add_addon: {
		body: {
			name: string
			quantity: number;
			price: string
			photo: File;
		},
		response: {
			
		}
	},
	update_addon: {
		body: {
			name: string | undefined
			quantity: number | undefined;
			price: string | undefined
			photo: File | undefined;
		},
		response: {

		}
	}
	update_package: {
		body: {
			name: string | undefined
			price: string | undefined
			comments: string | undefined;
			photo: File | undefined;
		},
		response: {

		}
	}
	delete_addon: {
		body: undefined;
		response: undefined;
	},
	cancel_booking_order: {
		body: undefined;
		response: undefined
	}
}

type ApiCommandDetailsEntry = {
	url: string;
	method: "POST" | "PUT" | "DELETE"
}

const ApiCommandDetails: Record<keyof ApiCommandTypes, ApiCommandDetailsEntry> = {
	create_branch: {
		url: "/api/branch/",
		method: "POST"
	},
	delete_branch: {
		url: "/api/branch/",
		method: "DELETE",
	},
	update_branch: {
		url: "/api/branch/",
		method: "PUT"
	},
	create_date_slot: {
		url: '/api/date-slot/',
		method: "POST"
	},
	update_date_slot: {
		url: '/api/date-slot/',
		method: "PUT"
	},
	delete_date_slot: {
		url: '/api/date-slot/',
		method: 'DELETE'
	},
	create_time_slot: {
		url: '/api/time-slot/',
		method: 'POST'
	},
	update_time_slot: {
		url: '/api/time-slot/',
		method: 'PUT'
	},
	delete_time_slot: {
		url: '/api/time-slot/',
		method: 'DELETE'
	},
	create_booking_session: {
		url: '/api/booking-session/',
		method: 'POST'
	},
	update_booking_session: {
		url: '/api/booking-session/',
		method: 'PUT'
	},
	add_addon: {
		url: '/api/add-on/',
		method: 'POST'
	},
	update_addon: {
		url: '/api/add-on/',
		method: 'PUT'
	},
	update_package: {
		url: '/api/admin/zone/',
		method: 'PUT'
	},
	delete_addon: {
		url: '/api/add-on/',
		method: 'DELETE'
	},
	cancel_booking_order: {
		url: '/api/admin/booking-order/',
		method: 'PUT'
	}
}

function apiCommand(): IssueCommandFunctionType {
	const [commandDialogContext, setCommandDialogContext] = useCommandDialogContext();

	const issueCommand: IssueCommandFunctionType = (user_spec) => {
		setCommandDialogContext({
			isOpen: true,
			spec: {
				...user_spec, 
				actionFun: (body) => {
					const url = ApiCommandDetails[user_spec.command_name].url
					const method = ApiCommandDetails[user_spec.command_name].method;

					let fetchPayload: any;

					switch (user_spec.mode || 'json') {
					case 'json': {
						fetchPayload = {
							method,
							body: JSON.stringify(body),
							headers: {
								'Content-Type': 'application/json;charset=UTF-8'
							}
						}
						break;
					}

					case 'multipart': {
						const formDataBody = new FormData()
						Object.entries(body).forEach(([k, v]) => formDataBody.append(k, v as any));
						fetchPayload = {
							method,
							body: formDataBody
						}
						break;
					}
					}

					return fetch(`${url}${user_spec.extra_path || ''}`,  fetchPayload).then(res => {
						if (res.status === 200) {
							res.json()
								.then(data => 
									user_spec.onSuccess && 
									user_spec.onSuccess(data)
								).catch(() => {
									user_spec.onSuccess && 
									user_spec.onSuccess(undefined)
								})
								
						}
						return res;
					})
				}
			}
		})
	}
	return issueCommand;
}

export { apiCommand };
