import { useState } from 'react';
import {
	Avatar,
	Box,
	Button,
	CircularProgress,
	Container,
	CssBaseline,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ROUTES from '../constants/routes';
import { Redirect } from 'react-router-dom';
import { useToken } from '../hooks/useToken';

function AdminLoginPage() {

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [token, setToken] = useToken();

	const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUsername(event.target.value);
	};

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		const token = btoa(`${username}:${password}`);

		const response = await fetch('/api/auth', {
			method: 'POST',
			headers: {
				'Authorization': `Basic ${token}`
			}
		});

		if (response.ok) {
			setToken(token);
		} else {
			alert('Invalid credentials');
		}
		setIsSubmitting(false)
	};

	if (token !== null) {
		return <Redirect to={ROUTES.BRANCH_ADMIN.to} />
	}

	return (
		<Container component="main"
			maxWidth="xs">
			<CssBaseline />
			<div style={{
				marginTop: 20,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
				<Avatar>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1"
					variant="h5">
						300F Admin Login
				</Typography>
				<form
					onSubmit={handleSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="username"
						label="Username"
						name="username"
						autoComplete="username"
						autoFocus
						value={username}
						onChange={handleUsernameChange}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						value={password}
						onChange={handlePasswordChange}
					/>
					<Button
						type="submit"
						fullWidth
						disabled={isSubmitting}
						variant="contained"
						color="primary"
					>
						Login
					</Button>
				</form>
			</div>
		</Container>
	);
}

export default AdminLoginPage;