import React from "react";
import { LanguageContext } from "../app/Context";

const dictionary = {
	arabic: {
		en: "عربي",
		ar: "عربي"
	},
	english: {
		en: "English",
		ar: "English"
	},
	home: {
		en: "Home",
		ar: "الرئيسية"
	},
	order_now: {
		en: "Order Now",
		ar: "اطلب الآن"
	},
	food_menu: {
		en: "Menu",
		ar: "قائمة الطعام"
	},
	photo_gallery: {
		en: "Gallery",
		ar: "ارشيف الصور"
	},
	about_us: {
		en: "About Us",
		ar: "نبذة عنا",
	},
	book_a_table: {
		en: "Book A Table",
		ar: "حجز طاولة"
	},
	new_branch: {
		en: "New Branch",
		ar: "فرع جديد"
	},
	book_now: {
		en: "Book Now",
		ar: "احجز الآن"
	},
	special: {
		en: "Special",
		ar: "خاصة"
	},
	bookings: {
		en: "Bookings",
		ar: "حجوزات"
	},
	specials: {
		en: "Specials",
		ar: "الخاصة"
	},
	spoons_welcoming: {
		en: "Visit and enjoy the Vibe of Our New Branch in Mahboula Spoons Complex",
		ar: "حياكم في فرعنا الجديد  بالمهبولة في مجمع سبونز"
	},
	create_memories: {
		en: "Create Your Special Moments",
		ar: "احتفل معنا بذكرياتك واصنع ذكريات جديده"
	},
	our_dishes: {
		en: "Our Dishes",
		ar: "اطباقنا"
	},
	"300f": {
		en: "300°F​",
		ar: "300°F​"
	},
	"300f_smokehouse": {
		en: "300°F​ Smokehouse",
		ar: "300°F مدخن​"
	},
	smoked_chicken: {
		en: "Smoked Chicken",
		ar: "الدجاج المدخن"
	},
	smoked_chicken_wings: {
		en: "Smoked Chicken Wing",
		ar: "اجنحة الدجاج المدخنة"
	},
	"300f_burger": {
		en: "300°F Burger",
		ar: "300°F برجر"
	},
	pulled_ribs: {
		en: "Pulled Ribs",
		ar: "ربز برجر"
	},
	"shrimp_bites": {
		en: "Shrimp bites",
		ar: "رقائق الربيان بالافوكادو"
	},
	"caesar_salad": {
		en: "Caesar Salad",
		ar: "سلطة السيزر"
	},
	"stuffed_jalapeno": {
		en: "Stuffed Jalapeno",
		ar: "الهالابينيو المحشي"
	},
	"wild_shrimp": {
		en: "Wild Shrimp",
		ar: "الوايلد شرمب"
	},
	view_full_menu: {
		en: "View Full Menu",
		ar: "قائمة الطعام"
	},
	discover: {
		en: "Discover",
		ar: "اكتشف"
	},
	our_story: {
		en: "Our Story",
		ar: "قصتنا"
	},
	some_from: {
		en: "Some From",
		ar: "من حسابنا"
	},
	our_instagram: {
		en: "Our Instagram",
		ar: "على الانستغرام"
	},
	on_instagram: {
		en: "On Instagram",
		ar: "حسابنا على انستقرام"
	},
	follow: {
		en: "Follow us",
		ar: "تابعونا على"
	},
	short_info: {
		en: "Short Info",
		ar: "بعض المعلومات"
	},
	get_in_touch: {
		en: "Get In Touch",
		ar: "تواصل معنا"
	},
	contact_us: {
		en: "Contact Us",
		ar: "تواصل معنا"
	},
	working_hours: {
		en: "Opening Hours",
		ar: "ساعات العمل"
	},
	"sunday-thursday": {
		en: "Sunady - Thursday",
		ar: "الأحد - الخميس"
	},
	"friday-saturday": {
		en: "Friday - Saturday",
		ar: "الجمعة - السبت"
	},
	pm: {
		"en": "PM",
		"ar": "مساءً"
	},
	am: {
		"en": "AM",
		"ar": "صباحاً"
	},
	follow_us: {
		en: "Follow Us",
		ar: "تابعونا"
	},
	facebook: {
		en: "Facebook",
		ar: "فيسبوك"
	},
	instagram: {
		en: "Instagram",
		ar: "انستقرام"
	},
	youtube: {
		en: "YouTube",
		ar: "يوتيوب"
	},
	whatsapp: {
		en: "WhatsApp",
		ar: "واتساب"
	},
	all_rights: {
		en: "All Rights Reserved",
		ar: "جميع الحقوق محفوظة"
	},
	developed_by: {
		en: "Developed By",
		ar: "تم التطوير والتنفيذ من قبل"
	},
	dev_name: {
		en: "Mohammed Alhaddar",
		ar: "محمد الهدار"
	},
	the_history: {
		en: "The History",
		ar: "البداية"
	},
	the_history_paragraph_1: {
		en: "It all started in 2018, when two old university mates caught up over dinner at a chalet in Al Khairan, Kuwait. Dr Mohammed brought with him some short ribs that he pre-smoked at his farm for dinner.",
		ar: "نشأت الفكرة في عام 2018 ، عندما التقى زملاء أيام الدراسة في الجامعة على العشاء في شاليه بمنطقة الخيران، بدولة الكويت وقد أحضر الدكتور محمد معه أضلاع البقر المدخنة. وقد كان الطبق حديث الطاولة."
	},
	the_history_paragraph_2: {
		en: "The smoked short ribs were the star of the event. Everybody was delighted, specially Dr. Khaled who thought, this dish deserved to be on a restaurant menu. Eventually, you can now enjoy it at 300°F Smokehouse. ",
		ar: "كان الجميع سعداء، خاصة الدكتور خالد الذي امن بوجود هذا الطبق بأحد المطاعم. و يمكنك الآن الاستمتاع به بمطعم 300°F Smokehouse"
	},
	our_mission: {
		en: "Our Mission",
		ar: "مهمتنا"
	},
	our_mission_paragraph_1: {
		en: "We strive to serve high quality food and ingredients sourced from primary supplier from around the world.",
		ar: "نسعى الى تقديم اطباق عالية الجودة وبمكونات مستورة من أفضل الموردين."
	},
	our_mission_paragraph_2: {
		en: "We aim to satisfy our customers through providing unique customer serves and create a memorable experience.",
		ar: "نطمح بتقديم خدمه مميزه لجعل تجربتكم فريدة."
	},
	our_mission_paragraph_3: {
		en: "Our values revolve around three main pillars which are social awareness, environmental stewardship and integrity.",
		ar: "نستمد قيمنا من ثلاثة اركان رئيسية وهم الوعي الاجتماعي والبيئي والنزاهة ."
	},
	locations: {
		en: "Locations",
		ar: "عنوان"
	},
	"have_a_question_?": {
		en: "Have a question?",
		ar: "هل لديكم أي استفسار؟"
	},
	"leave_a_message": {
		en: "Leave a message",
		ar: "اترك لنا رسالة"
	},
	name: {
		en: "Name",
		ar: "الإسم"
	},
	phone_number: {
		en: "Phone Number",
		ar: "رقم الهاتف"
	},
	email: {
		en: "Email",
		ar: "البريد الإلكتروني"
	},
	message: {
		en: "Message",
		ar: "الرسالة"
	},
	branch: {
		en: "Branch",
		ar: "الفرع"
	},
	send: {
		en: "Send",
		ar: "أرسل الرسالة"
	},
	"most_delicious": {
		en: "Most Delicious",
		ar: "ألذ"
	},
	"appetizers": {
		en: "APPETIZERS",
		ar: "المقبلات"
	},
	"hot_dishes": {
		en: "Hot Dishes",
		ar: "الاطباق الرئيسية",
	},
	"ribeye": {
		en: "Ribeye",
		ar: ""
	},
	"smoked_ribs": {
		en: "Smoked Ribs",
		ar: "أضلاع مدخنة"
	},
	"step": {
		en: "Step",
		ar: "الخطوة"
	},
	"booking_step1": {
		en: "Select Branch, Date & Time for your reservation",
		ar: "اختر الفرع، التاريخ والوقت للحجز"
	},
	"booking_step2": {
		en: "Select a table",
		ar: "اختر الطاولة"
	},
	"booking_step3": {
		en: "Personal Details",
		ar: "البيانات الشخصية"
	},
	"booking_step4": {
		en: "Select Addons",
		ar: "الإضافات"
	},
	"booking_step5": {
		en: "Select Payment method",
		ar: "اختيار طريقة الدفع"
	},
	date: {
		en: "Date",
		ar: "التاريخ"
	},
	no_of_people: {
		en: "Number Of People",
		ar: "عدد الأشخاص"
	},
	time: {
		en: "Time",
		ar: "الوقت"
	},
	full_name: {
		en: "Full Name",
		ar: "الإسم الكامل"
	},
	occasion: {
		en: "Occasion",
		ar: "المناسبة"
	},
	"optional_details": {
		en: "Optional Details",
		ar: "تفاصيل إضافية"
	},
	"optioanl_details_help_text": {
		en: "Occasion type and your relation to the person for the welcome sign on the table.",
		ar: "نوع المناسبة و العلاقة بالشخص - لإعداد لوحة الترحيب على الطاولة"
	},
	"pay_by_knet": {
		ar: "كي نت",
		en: "K-NET"
	},
	"pay_by_credit_network": {
		ar: "فيزا\\ماستر كارد",
		en: "Visa/MasterCard"
	},
	"total_amount": {
		ar: "المبلغ المستحق",
		en: "Total Amount"
	},
	"payment_method": {
		en: "Payment Method",
		ar: "طريقة الدفع"
	},
	"404_not_found": {
		en: "Not Found",
		ar: "المحتوى غير متوفر"
	},
	selecting_branch: {
		ar: "اختيار الفرع",
		en: "Selecting Branch"
	},
	selecting_date: {
		ar: "اختيار التاريخ",
		en: "Selecting Date"
	},
	selecting_time: {
		ar: "اختيار الوقت",
		en: "Selecting Time"
	},
	selecting_people_number: {
		ar: "اختيار عدد الأشخاص",
		en: "Selecting number of people"
	},
	KWD: {
		en: "KWD",
		ar: "د.ك."
	},
	package: {
		en: "Package",
		ar: "الباقة"
	},
	something_went_wrong: {
		en: "Something went wrong",
		ar: "حدث خطأ ما"
	},
	coming_soon: {
		en: "Coming Soon",
		ar: "انتظرونا قريباً"
	},
	select_your_country: {
		en: "Select your country",
		ar: "اختر الدولة"
	},
	kuwait: {
		en: "Kuwait",
		ar: "الكويت"
	},
	saudi: {
		en: "Saudi Arabia",
		ar: "السعودية"
	},
	shuwaikh: {
		en: "Shuwaikh",
		ar: "الشويخ"
	},
	spoons: {
		en: "Spoons",
		ar: "سبونز"
	},
	"order_id": {
		"en": "Order Id",
		"ar": "معرف الطلب"
	},
	"reference_id": {
		"en": "Reference Id",
		"ar": "معرف الإشارة"
	},
	"payment_date": {
		"en": "Payment Date",
		"ar": "تاريخ الدفع"
	},
	"payment_amount": {
		"en": "Payment Amount",
		"ar": "مبلغ الدفع"
	},
	"payment_status": {
		"en": "Payment Status",
		"ar": "حالة الدفع"
	},
	"transaction_succeed": {
		"en": "Transaction Succeed",
		"ar": "نجاح العملية"
	},
	"transaction_failed": {
		"en": "Transaction Failed",
		"ar": "فشل العملية"
	},
	"submitted_successfully": {
		"en": "Submitted Successfully",
		"ar": "تم التقديم بنجاح"
	},
	"submitted": {
		"en": "Submitted",
		"ar": "تم الإرسال"
	},
	"validation_error": {
		"en": "Validation Error",
		"ar": "خطأ في التحقق من البيانات"
	},
	"an_error_occurred": {
		"en": "An error occurred",
		"ar": "حدث خطأ ما"
	},
	"submit": {
		"en": "Submit",
		"ar": "إرسال"
	},
	"cancel": {
		"en": "Cancel",
		"ar": "إلغاء"
	},
	"reserving_tables": {
		"en": "Reserving Tables",
		"ar": "حجز الطاولات"
	},
	"submit_personal_details": {
		"en": "Submit Personal Details",
		"ar": "إرسال التفاصيل الشخصية"
	},
	"submit_addons": {
		"en": "Submit Addons",
		"ar": "إرسال الملحقات"
	},
	"preparing_payment": {
		"en": "Preparing Payment",
		"ar": "إعداد الدفع"
	},
	"initializing_booking_session": {
		"en": "Initializing Booking Session",
		"ar": "تهيئة جلسة الحجز"
	},
	"no_addons_available": {
		"en": "No addons are available at the moment",
		"ar": "لا تتوفر حاليًا أي ملحقات"
	},
	"price": {
		"en": "Price",
		"ar": "السعر"
	},
	"photo": {
		"en": "Photo",
		"ar": "الصورة"
	},
	"quantity": {
		"en": "Quantity",
		"ar": "الكمية"
	},
	"next": {
		en: "Next",
		ar: "التالي"
	},
	"loading_tables": {
		en: "Loading Tables Data",
		ar: "انتظار بيانات الطاولات"
	},
	"description": {
		en: "Description",
		ar: "الوصف"
	},
	"package_price": {
		en: "Package Price",
		ar: "سعر الباقة"
	},
	"addons_price": {
		en: "Addons Price",
		ar: "سعر الإضافات"
	},
	"package_details": {
		en: "Package Details",
		ar: "تفاصيل الباقة"
	}
}

const useLanguage = () => {
	return React.useContext(LanguageContext);
}

const translate: (key: keyof typeof dictionary) => string = (key) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [language] = useLanguage();
	return dictionary[key][language];
}

export { translate, useLanguage }