
import BrandLogo from "../images/logo.png";
import { AppBar as MuiAppBar, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import ROUTES from "../constants/routes";
import { useToken } from "../hooks/useToken";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar)(({ theme }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	width: `calc(100% - ${drawerWidth}px)`,
	marginLeft: `${drawerWidth}px`,
}));


const DrawerHeader = styled('div')<{ type: "drawer" | "main" }>(({ theme, type }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	backgroundColor: type === "drawer" ? theme.palette.primary.main : undefined,
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

const Main = styled('main')(({ theme }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	minHeight: "calc(100% - 48px)",
	backgroundColor: "#F0F0F0",
	marginLeft: `${drawerWidth}px`,
}));

const AdminLayout: React.FC = ({ children }) => {
	const [token, setToken] = useToken();
	return (
		<div style={{
			width: "100%",
			height: "100%"
		}}>
			<AppBar position="fixed"
				color="primary">
				<Toolbar>
					<Typography>
						Admin Panel
					</Typography>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="persistent"
				anchor="left"
				open={true}
			>
				<DrawerHeader type="drawer">
					<div style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						width: "100%",
						padding: "10px 0px 0px 0px"
					}}>
						<Link to={{ pathname: ROUTES.HOME.to }}>
							<img src={BrandLogo}
								alt="300F Logo"
								style={{ height: "40px" }} />
						</Link>
					</div>
				</DrawerHeader>
				<Divider />
				<List>
					<ListItem 
						disablePadding
					>
						<ListItemButton 
							component={Link}
							to={ROUTES.BRANCH_ADMIN.to}
						>
							<ListItemText primary={"Branches"} />
						</ListItemButton>
					</ListItem>
					<ListItem 
						disablePadding
					>
						<ListItemButton
							component={Link}
							to={ROUTES.CALENDAR_ADMIN.to}
						>
							<ListItemText primary={"Calendar"} />
						</ListItemButton>
					</ListItem>
					<ListItem 
						disablePadding
					>
						<ListItemButton
							component={Link}
							to={ROUTES.ADDON_MANAGEMENT_ADMIN.to}
						>
							<ListItemText primary={"Addons Management"} />
						</ListItemButton>
					</ListItem>

					<ListItem 
						disablePadding
					>
						<ListItemButton
							component={Link}
							to={ROUTES.PACKAGE_MANAGEMENT_ADMIN.to}
						>
							<ListItemText primary={"Package Management"} />
						</ListItemButton>
					</ListItem>

					<ListItem 
						disablePadding
					>
						<ListItemButton
							component={Link}
							to={ROUTES.FOOTER_MANAGEMENT.to}
						>
							<ListItemText primary={"Footer Management"} />
						</ListItemButton>
					</ListItem>

					<ListItem 
						disablePadding
					>
						<ListItemButton
							onClick={() => {
								setToken(null);
							}}
						>
							<ListItemText primary={"Logout"} />
						</ListItemButton>
					</ListItem>
				</List>
			</Drawer>
			<Main>
				<DrawerHeader type="main" />
				<div>
					{children}
				</div>
			</Main>
		</div>
	)
}

export default AdminLayout;