import React from "react";
import { CommandDialogSpec } from "../functions/apiCommand";

export type CommandDialogContextType = {
	isOpen: boolean;
	spec: CommandDialogSpec<any> | undefined;
} 

const CommandDialogContext = React.createContext<[CommandDialogContextType, React.Dispatch<React.SetStateAction<CommandDialogContextType>>]>([
	{
		isOpen: false,
		spec: undefined
	}, (x) => x
]);


const useCommandDialogContext = () => {
	return React.useContext(CommandDialogContext);
}

export {CommandDialogContext, useCommandDialogContext};