import Footer from "../components/Footer";
import { Header } from "../components/Header";


const CustomerLayout: React.FC = ({ children }) => {
	return (
		<>
			<Header />
			{children}
			<Footer />
		</>
	)
}

export default CustomerLayout;