import HomePage from '../pages/HomePage';
import AboutUsPage from "../pages/AbousUsPage";
import PhotoGalleryPage from "../pages/PhotoGalleryPage";
import ContactUsPage from "../pages/ContactUsPage";
import FoodMenuPage from '../pages/FoodMenuPage';
import BookATablePage from '../pages/BookATablePage';
import PaymentQueryPage from '../pages/PaymentQuery';
import BranchAdminPage from '../pages/BranchAdminPage';
import DateSlotAdminPage from '../pages/DateSlotAdminPage';
import TimeSlotAdminPage from '../pages/TimeSlotAdminPage';
import CalendarAdminPage from '../pages/CalendarAdminPage';
import AddonsManagementPage from '../pages/AddonManagementPage';
import AdminLoginPage from '../pages/AdminLoginPage';
import PackageManagementPage from '../pages/PackageManagementPage';
import FooterManagementPage from '../pages/FooterManagementPage';

type Route = {
	to: string;
	component: React.FC,
	layout: "customer-facing" | "admin" | "none"
}

const makeRoutes: <T extends string>(arg: Record<T, Route>) => Record<T, Route> = (x) => x;


const ROUTES = makeRoutes({
	HOME: {
		to: "/",
		component: HomePage,
		layout: "customer-facing"
	},
	ABOUT_US: {
		to: "/about-us",
		component: AboutUsPage,
		layout: "customer-facing"
	},
	PHOTO_GALLERY: {
		to: "/photo-gallery",
		component: PhotoGalleryPage,
		layout: "customer-facing"
	},
	CONTACT_US: {
		to: "/contact-us",
		component: ContactUsPage,
		layout: "customer-facing"
	},
	FOOD_MENU: {
		to: "/food-menu",
		component: FoodMenuPage,
		layout: "customer-facing"
	},
	BOOK_A_TABLE: {
		to: "/book-a-table",
		component: BookATablePage,
		layout: "customer-facing"
	},
	MODIFY_A_TABLE: {
		to: "/book-a-table/:bookingSessionId",
		component: BookATablePage,
		layout: "customer-facing"
	},
	PAYMENT_QUERY: {
		to: "/payment-query",
		component: PaymentQueryPage,
		layout: "customer-facing"
	},
	CALENDAR_ADMIN: {
		to: "/admin/calendar",
		component: CalendarAdminPage,
		layout: "admin"
	},
	BRANCH_ADMIN: {
		to: "/admin/branch",
		component: BranchAdminPage,
		layout: "admin"
	},
	DATE_SLOT_ADMIN: {
		to: "/admin/date-slot/:branchId",
		component: DateSlotAdminPage,
		layout: "admin"
	},
	TIME_SLOT_ADMIN: {
		to: "/admin/time-slot/:dateSlotId",
		component: TimeSlotAdminPage,
		layout: "admin"
	},
	ADDON_MANAGEMENT_ADMIN: {
		to: "/admin/addon-management",
		component: AddonsManagementPage,
		layout: "admin"
	},
	PACKAGE_MANAGEMENT_ADMIN: {
		to: "/admin/package-management",
		component: PackageManagementPage,
		layout: "admin"
	},
	FOOTER_MANAGEMENT: {
		to: "/admin/footer-management",
		component: FooterManagementPage,
		layout: "admin",
	},
	ADMIN_LOGIN_PAGE: {
		to: "/admin/login",
		component: AdminLoginPage,
		layout: "none"
	}
})

export default ROUTES;