import { Button, FormControl, InputLabel, MenuItem, Paper, Select, styled, TextField } from "@mui/material";
import LandingHeading from "../components/LandingHeading";
import PageHeading from "../components/PageHeading";
import ThreeFLink from "../components/ThreeFLink";
import { ADDRESS } from "../constants/address";
import { backgroundGray } from "../constants/colors";
import { PHONE_NUMBER } from "../constants/phoneNumber";
import { translate, useLanguage } from "../hooks/useTranslate";
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import Banner from "../images/banner/banner-1.jpg"

const CssTextField = styled(TextField, {
	shouldForwardProp: (props) => props !== "focusColor"
})(() => ({
	'& label': {
		lineHeight: 1.6,
	},
}));


const ContactUsPage = () => {
	const [lang] = useLanguage();
	const title = lang === "ar" ? translate("locations") : translate("300f_smokehouse");
	const subTitle = lang === "ar" ? translate("300f_smokehouse") : translate("locations")
	return (
		<>
			<PageHeading
				title={translate("contact_us")}
				backgroundImage={Banner}
			/>
			<div style={{
				backgroundColor: backgroundGray,
				padding: "50px 0px",
				display: "flex",
				flexDirection: "column",
				justifyContent: 'center',
				alignItems: "center"
			}}>
				<div style={{
					display: "flex",
					flexDirection: "row",
					flexWrap: "wrap",
					justifyContent: "center",
					gap: 20
				}}>
					<div style={{
						flexBasis: "500px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center"
					}}>
						<LandingHeading
							title={title}
							subtitle={subTitle}
						/>
						<span style={{ color: "white" }}>{ADDRESS}</span>
						<ThreeFLink style={{ color: "white" }}
							href="mailto:hello@fahrenheits.com">hello@fahrenheits.com</ThreeFLink>
						<ThreeFLink 
							style={{
								color: "white",
								direction: "ltr"
							}}
							href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</ThreeFLink>
					</div>

					<div style={{
						flexBasis: "500px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center"
					}}>
						<LandingHeading
							title={translate("have_a_question_?")}
							subtitle={translate("leave_a_message")}
						/>
						<Paper style={{
							display: "flex",
							flexDirection: "column",
							gap: 10,
							marginTop: 20,
							padding: "20px 20px"
						}}>
							<CssTextField
								hiddenLabel
								InputProps={{
									startAdornment: <PersonIcon />
								}}
								label={translate("name")}
								color="primary"
							/>
							<FormControl fullWidth>
								<InputLabel id="branch">{translate("branch")}</InputLabel>
								<Select
									startAdornment={<StoreIcon />}
									inputProps={{ MenuProps: { disableScrollLock: true } }}
									labelId="branch"
									id="demo-simple-select"
									// value={age}
									label="Branch"
								// onChange={handleChange}
								>
									<MenuItem value={10}>Ten</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem>
								</Select>
							</FormControl>
							<CssTextField
								InputProps={{
									startAdornment: <LocalPhoneIcon />
								}}
								label={translate("phone_number")}
								color="primary"
							/>
							<CssTextField
								InputProps={{
									startAdornment: <EmailIcon />
								}}
								label={translate("email")}
								color="primary"
							/>
							<CssTextField
								InputProps={{
									startAdornment: <MessageIcon />
								}}
								multiline
								rows={3}
								label={translate("message")}
								color="primary"
							/>
							<Button
								startIcon={<SendIcon />}
								variant="contained">{translate("send")}
							</Button>
						</Paper>

					</div>

				</div>

			</div>
		</>
	)
}

export default ContactUsPage;