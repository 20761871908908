import LandingHeading from "../components/LandingHeading";
import PageHeading from "../components/PageHeading";
import { translate, useLanguage } from "../hooks/useTranslate";
import Banner from "../images/banner/banner-1.jpg"

import HeroBackground1 from "../images/section-banner/mouth-watering.jpg"
import HeroBackground2 from "../images/section-banner/most-delicious.jpg"

import menu1 from "../images/menu/menu1.jpg"
import menu2 from "../images/menu/menu2.jpg"
import React from "react";

type FoodMenuHeroProps = {
	index: number;
	title: string;
	subtitle: string;
	menuItems: string[];
	image: string;
}

const FoodMenuHero: React.FC<FoodMenuHeroProps> = ({ index, title, subtitle, menuItems, image }) => {
	const backgroundImage = [HeroBackground1, HeroBackground2][index % 2];
	return (
		<div style={{
			display: "grid",
			gridTemplateRows: "1fr",
			gridTemplateColumns: "repeat(auto-fit, minmax(200px, 400px))",
			gap: 50,
			backgroundImage: `url(${backgroundImage})`,
			backgroundSize: "cover",
			justifyContent: "center",
			padding: "50px 0px",
			flexWrap: "wrap"
		}}>
			<div style={{
				display: "flex",
				flexDirection: "column",
				justifySelf: "center",
				margin: 20,
				gap: 30,
				order: (index + 1) % 2
			}}>
				<div>
					<LandingHeading
						title={title}
						subtitle={subtitle}
						mode={index % 2 === 0 ? "light" : "dark"}
					/>
				</div>
				<div style={{
					display: "grid",
					gridTemplateColumns: "repeat(auto-fill, minmax(40px, 400px))",
					gap: 20
				}}>
					{menuItems.map(item => <div style={{
						color: index % 2 === 0 ? "white" : "black",
						borderBottom: `1px solid ${index % 2 === 0 ? "white" : "black"}`,
						paddingBottom: 5
					}}>{item}</div>)}
				</div>
			</div>
			<div style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				justifySelf: "center",
			}}>
				<img
					src={image}
					style={{
						maxWidth: "100%",
						borderRadius: "100%",
						border: "3px solid white"
					}}
					alt="Menu"
				/>
			</div>
		</div>
	)
}

const FoodMenuPage = () => {

	const [lang] = useLanguage();
	return (
		<>
			<PageHeading
				title={translate("food_menu")}
				backgroundImage={Banner}
			/>
			<FoodMenuHero
				index={0}
				title={lang === "en" ? translate("300f_smokehouse") : translate("our_dishes")}
				subtitle={translate("specials")}
				menuItems={[
					translate("smoked_chicken"),
					translate("smoked_chicken_wings"),
					translate("300f_burger"),
					translate("pulled_ribs")
				]}
				image={menu1}
			/>
			<FoodMenuHero
				index={1}
				title={translate("most_delicious")}
				subtitle={translate("appetizers")}
				menuItems={[
					translate("shrimp_bites"),
					translate("caesar_salad"),
					translate("stuffed_jalapeno"),
					translate("wild_shrimp")
				]}
				image={menu2}
			/>
			<FoodMenuHero
				index={2}
				title={translate("most_delicious")}
				subtitle={translate("hot_dishes")}
				menuItems={[
					translate("smoked_ribs"),
				]}
				image={menu1}
			/>
		</>
	)
}

export default FoodMenuPage;