import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function ScrollToTop() {
	const history = useHistory();
	useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo({
				behavior: "smooth",
				left: 0,
				top: 0
			});
		});
		return () => {
			unlisten();
		}
	}, []);

	return (null);
}

export default ScrollToTop;