import { Button, CircularProgress, Paper, TextField, Typography } from "@mui/material"
import { generatePath, Link, useRouteMatch } from "react-router-dom";
import ROUTES from "../constants/routes";
import { apiCommand } from "../functions/apiCommand";
import { apiQuery } from "../functions/apiQuery";


const DateSlotAdminPage: React.FC = ({ }) => {
	const match = useRouteMatch<{ branchId: string }>();
	const branchId = match.params.branchId;

	const [dateSlots, reloadDateSlots] = apiQuery("list_date_slots", `/api/branch/${branchId}/date-slots`);
	const [branches, reloadBranches] = apiQuery("list_branches", `/api/branch/`);
	const command = apiCommand();
	
	if (!dateSlots || !branches) {
		return <CircularProgress />;
	}

	const branch = branches.find(x => x.id === branchId);

	if (!branch) {
		return <CircularProgress />;
	}

	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			gap: 10
		}}>
			<div style={{
				display: "flex",
				flexDirection: "row",
				gap: 20
			}}>
				<Typography
					variant="h6"
					fontWeight="bold">
					Date Slots For ({`${branch.name_en}`} - {branch.name_ar})
				</Typography>
				<div>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							command({
								title: "Create Date Slot",
								command_name: "create_date_slot",
								initialState: {
									branchId: branchId,
								},
								body: (state, setState) => (
									<div style={{
										display: "flex",
										flexDirection: "column",
										gap: 10
									}}>
										<TextField
											fullWidth
											required
											label="Date From"
											helperText="Must be in format dd-MM-yyyy e.g. 12-02-2022"
											value={state.dateFrom || ''}
											onChange={(e) => setState({ dateFrom: e.target.value })}
										/>
										<TextField
											fullWidth
											required
											label="Date To"
											value={state.dateTo || ''}
											helperText="Must be in format dd-MM-yyyy e.g. 12-02-2022"
											onChange={(e) => setState({ dateTo: e.target.value })}
										/>
									</div>
								),
								onSuccess: () => reloadDateSlots()
							})
						}}
					>
						Create Date Slot
					</Button>
				</div>
			</div>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: 10
			}}>
				{
					dateSlots.length === 0 && (
						<Typography variant="subtitle1">No date slots are availble under this branch</Typography>
					)
				}
				{
					dateSlots.map(dateSlot => {
						return (
							<Paper
								id={dateSlot.id}
								style={{ padding: 10 }}
							>
								<div style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between"
								}}>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											gap: 5,
											alignItems: "center"
										}}
									>
										<Typography
											variant="subtitle1"
											style={{ display: "inline" }}
										>
											From:
										</Typography>
										<Typography
											fontWeight="bold"
											variant="subtitle2"
											style={{ display: "inline" }}
										>

											{dateSlot.dateFrom}
										</Typography>
										<Typography
											variant="subtitle1"
											style={{ display: "inline" }}
										>
											To:
										</Typography>
										<Typography
											fontWeight="bold"
											variant="subtitle2"
											style={{ display: "inline" }}
										>
											{dateSlot.dateTo}
										</Typography>
									</div>
									<div style={{
										gap: 10,
										display: "flex",
										alignItems: "center"
									}}>
										<Typography variant="caption">Date Slot ID: {dateSlot.id}</Typography>
										<Button
											variant="contained"
											color="error"
											onClick={() => {
												command({
													command_name: "delete_date_slot",
													title: "Delete Date Slot",
													extra_path: `${dateSlot.id}`,
													body: () => (
														<>
															<Typography
																variant="subtitle1"
																style={{ display: "inline" }}
															>
																From:
															</Typography>
															<Typography
																fontWeight="bold"
																variant="subtitle2"
																style={{ display: "inline" }}
															>

																{dateSlot.dateFrom}
															</Typography>
															<Typography
																variant="subtitle1"
																style={{ display: "inline" }}
															>
																To:
															</Typography>
															<Typography
																fontWeight="bold"
																variant="subtitle2"
																style={{ display: "inline" }}
															>
																{dateSlot.dateTo}
															</Typography>
														</>
													),
													onSuccess: () => reloadDateSlots()
												})
											}}
										>
											Delete Date Slot
										</Button>
										<Button
											variant="contained"
											color="success"
											onClick={() => {
												command({
													title: "Create Date Slot",
													command_name: "update_date_slot",
													initialState: {
														branchId: branchId,
														dateFrom: dateSlot.dateFrom,
														dateTo: dateSlot.dateTo
													},
													extra_path: `${dateSlot.id}`,
													body: (state, setState) => (
														<div style={{
															display: "flex",
															flexDirection: "column",
															gap: 10
														}}>
															<TextField
																fullWidth
																required
																label="Date From"
																helperText="Must be in format dd-MM-yyyy e.g. 12-02-2022"
																value={state.dateFrom || ''}
																onChange={(e) => setState({ dateFrom: e.target.value })}
															/>
															<TextField
																fullWidth
																required
																label="Date To"
																value={state.dateTo || ''}
																helperText="Must be in format dd-MM-yyyy e.g. 12-02-2022"
																onChange={(e) => setState({ dateTo: e.target.value })}
															/>
														</div>
													),
													onSuccess: () => reloadDateSlots()
												})
											}}
										>
											Update Date Slot
										</Button>
									</div>
								</div>
								<Link to={generatePath(ROUTES.TIME_SLOT_ADMIN.to, {dateSlotId: dateSlot.id})}>Manage Time Slots</Link>
							</Paper>
						)
					})
				}
			</div>
		</div>)
}

export default DateSlotAdminPage;