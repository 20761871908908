function flatten<T>(arr: T[]): T[] {
	const flattened: T[] = [];
  
	arr.forEach((item) => {
	  if (Array.isArray(item)) {
			flattened.push(...flatten(item));
	  } else {
			flattened.push(item);
	  }
	});
  
	return flattened;
}

export default flatten;