import { Box, SwipeableDrawer } from "@mui/material"
import { darkGray, darkPrimaryColor } from "../constants/colors";
import { useDrawer } from "../context/DrawerContext";
import { translate, useLanguage } from "../hooks/useTranslate";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CollectionsIcon from '@mui/icons-material/Collections';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CallIcon from '@mui/icons-material/Call';
import { PHONE_NUMBER } from "../constants/phoneNumber";
import BrandLogo from "../images/logo.png";
import ROUTES from "../constants/routes";
import { ORDER_NOW_LINK } from "../constants/orderNow";
import { Link } from "react-router-dom";
import React from "react";


type DrawerEntry = {
	label: string;
	// to: string;
	icon: React.ReactNode;
	action: {
		type: "link",
		to: string
	} | {
		type: "callback",
		callback: () => void
	} | {
		type: "external_link",
		to: string;
	}
}



const MainDrawer = () => {
	const [lang, setLang] = useLanguage();
	const [isDrawerOpen, setDrawerOpen] = useDrawer();

	const toggleLang = () => {
		const revMap = {
			"en": "ar",
			"ar": "en"
		} as const

		setLang(revMap[lang]);
	}

	const DrawerEntries: DrawerEntry[] = [
		{
			label: translate("home"),
			icon: <HomeIcon />,
			action: {
				type: "link",
				to: ROUTES.HOME.to
			}
		},
		{
			label: translate("order_now"),
			icon: <ShoppingCartCheckoutIcon />,
			action: {
				type: "external_link",
				to: ORDER_NOW_LINK
			}
		},
		{
			label: translate("book_a_table"),
			icon: <TableRestaurantIcon />,
			action: {
				type: "link",
				to: ROUTES.BOOK_A_TABLE.to
			}
		},
		{
			label: translate("about_us"),
			icon: <InfoIcon />,
			action: {
				type: "link",
				to: ROUTES.ABOUT_US.to
			}
		},
		{
			label: translate("food_menu"),
			icon: <RestaurantMenuIcon />,
			action: {
				type: "link",
				to: ROUTES.FOOD_MENU.to
			}
		},
		{
			label: translate("photo_gallery"),
			icon: <CollectionsIcon />,
			action: {
				type: "link",
				to: ROUTES.PHOTO_GALLERY.to
			}

		},
		{
			label: translate("contact_us"),
			icon: <ContactSupportIcon />,
			action: {
				type: "link",
				to: ROUTES.CONTACT_US.to
			}
		},
		{
			label: translate(lang === "ar" ? "english" : "arabic"),
			icon: <LanguageIcon />,
			action: {
				type: "callback",
				callback: () => toggleLang()
			}
		},
		{
			label: PHONE_NUMBER,
			icon: <CallIcon />,
			action: {
				type: "external_link",
				to: `tel:${PHONE_NUMBER}`
			}
		}
	]

	const renderListItem = ({ action, icon, label }: DrawerEntry) => {
		const list_item_children = (
			<>
				<ListItemIcon style={{ color: "white" }}>
					{icon}
				</ListItemIcon>
				<ListItemText
					primaryTypographyProps={{
						style: {
							textAlign: lang === "en" ? "left" : "right",
							direction: "ltr"
						}
					}}
					style={{ color: "white" }}
					primary={label} />
			</>
		)
		switch (action.type) {
		case "link": {
			return <ListItemButton
				component={Link}
				to={action.to}
				onClick={() => setDrawerOpen(false)
				}
			>
				{list_item_children}
			</ListItemButton>
		}
		case "external_link": {
			return <ListItemButton
				component={"a"}
				href={action.to}
				target="_blank"
				onClick={() => setDrawerOpen(false)
				}
			>
				{list_item_children}
			</ListItemButton>
		}
		case "callback": {
			return (<ListItemButton
				onClick={() => {
					setDrawerOpen(false);
					action.callback();
				}}
			>
				{list_item_children}
			</ListItemButton>
			);
		}
		}
	}

	return (
		<>
			{/* @ts-ignore */}
			<SwipeableDrawer
				anchor={"left"}
				open={isDrawerOpen}
				onClose={() => setDrawerOpen(false)}
				onOpen={() => setDrawerOpen(true)}
			>
				<Box sx={{
					width: 300,
					backgroundColor: darkGray,
					minHeight: "100%"
				}}>
					<div style={{
						backgroundColor: darkPrimaryColor,
						height: "65px",
						padding: 15,
						display: "flex",
						flexDirection: "row",
						justifyContent: "center"
					}}>
						<img src={BrandLogo}
							alt="Brand Logo"
							style={{ height: "100%" }} />
					</div>
					<List>
						{
							DrawerEntries.map((entry) => (
								<ListItem key={entry.label}
									disablePadding>
									{renderListItem(entry)}
								</ListItem>
							))
						}
					</List>
					<Divider />
				</Box>
			</SwipeableDrawer >
		</>
	)
}

export default MainDrawer;