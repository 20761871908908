import { FooterData } from "../components/Footer";
import React, {useState, useEffect} from "react"
import { CircularProgress } from '@mui/material';
import { TextField, Button, Paper, Grid, Typography, Alert } from '@mui/material';



function FooterManagementPage() {
	const [footerData, setFooterData] = useState<FooterData>({
		contactUsArabic: "",
		contactUsEnglish: "",
		email: "",
		openingHours: [],
		socialMediaLinks: []
	});
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		fetch("/api/footer/", { method: 'GET'})
			.then(data => data.json())
			.then(x => setFooterData(x))
			.finally(() => setLoading(false));
	}, [])

	
	const handleInputChange = (e: any) => {
		setFooterData({ 
			...footerData,
			[e.target.name]: e.target.value 
		});
	};

	const handleArrayInputChange = (index: number, key: string, value: string, arrayKey: 'socialMediaLinks' | 'openingHours') => {
		const updatedArray: any = [...footerData[arrayKey]];
		updatedArray[index][key] = value;
		setFooterData({ ...footerData,
			[arrayKey]: updatedArray });
	};

	const onSubmit = (e: any) => {
		e.preventDefault();
		
		setLoading(true);
		fetch('/api/footer/update', {method: 'POST',
			body: JSON.stringify(footerData),
			headers: {
				'Content-Type': 'application/json'
			}})
			.then(data => data.json())
			.then(x => setFooterData(x))
			.finally(() => setLoading(false));

	};
	
	return (
		<form>
			<Alert 
				severity="info"
				style={{marginBottom: 10}} 
			>
					For Social Media Images, you need to provide them in Base64 format. You can find these 
				{' '}<a 
					target="_blank"
					href="https://www.iconfinder.com">
							here.
				</a>
			</Alert>
			<Paper style={{ padding: 16 }}>
				<Grid 
					container
					alignItems="flex-start"
					spacing={2}
				>
					<Grid item
						xs={12}>
						<Typography variant="h6">General</Typography>
					</Grid>
					<Grid item
						xs={12}>
						<TextField
							disabled={isLoading}
							label="Contact Us (English)"
							name="contactUsEnglish"
							value={footerData.contactUsEnglish}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>

					<Grid item
						xs={12}>
						<TextField
							disabled={isLoading}
							label="Contact Us (Arabic)"
							name="contactUsArabic"
							value={footerData.contactUsArabic}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>

					<Grid item
						xs={12}>
						<TextField
							disabled={isLoading}
							label="Email"
							name="email"
							value={footerData.email}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>

					<Grid 
						item
						xs={12}
						style={{display: 'flex',
							flexDirection: 'row',
							gap: 5
						}}
					>
						<Typography variant="h6">Opening Hours</Typography>
						<Button
							variant="outlined"
							size="small"
							color="primary"
							onClick={() => {
								setFooterData((data) => ({
									...data as FooterData,
									openingHours: [...data?.openingHours, {
										day_ar: "",
										day_en: "",
										hours_ar: "",
										hours_en: "",
									}]
								}))
							}}
						>
								+ Add
						</Button>
					</Grid>

					{footerData.openingHours.map((item, index) => (
						<React.Fragment key={`${index}-opening-hours`}>
							<Grid item
								xs={0.25}>
								<div style={{fontWeight: 'bold'}}>
									{index + 1}.
								</div>
							</Grid>
							<Grid item
								xs={2}>
								<TextField
									disabled={isLoading}
									label="Day (English)"
									value={item.day_en}
									onChange={(e) => handleArrayInputChange(index, 'day_en', e.target.value, 'openingHours')}
									fullWidth
								/>
							</Grid>
							<Grid item
								xs={3}>
								<TextField
									disabled={isLoading}
									label="Day (Arabic)"
									value={item.day_ar}
									onChange={(e) => handleArrayInputChange(index, 'day_ar', e.target.value, 'openingHours')}
									fullWidth
								/>
							</Grid>
							<Grid item
								xs={3}>
								<TextField
									disabled={isLoading}
									label="Hours (English)"
									value={item.hours_en}
									onChange={(e) => handleArrayInputChange(index, 'hours_en', e.target.value, 'openingHours')}
									fullWidth
								/>
							</Grid>
							<Grid item
								xs={3}>
								<TextField
									disabled={isLoading}
									label="Hours (Arabic)"
									dir="rtl"
									value={item.hours_ar}
									onChange={(e) => handleArrayInputChange(index, 'hours_ar', e.target.value, 'openingHours')}
									fullWidth
								/>
							</Grid>
							<Grid item
								xs={0.75}>
								<Button
									onClick={() => {
										setFooterData(data => ({
											...data,
											openingHours: data.openingHours.filter((_, t) => t !== index)
										}))
									}}
								>Delete</Button>
							</Grid>
						</React.Fragment>
					))}

					<Grid 
						item
						xs={12}
						style={{display: 'flex',
							flexDirection: 'row',
							gap: 5
						}}
					>
						<Typography variant="h6">Social Media Links</Typography>
						<Button
							variant="outlined"
							size="small"
							color="primary"
							onClick={() => {
								setFooterData((data) => ({
									...data as FooterData,
									socialMediaLinks: [...data?.socialMediaLinks, {
										name_ar: "",
										name_en: "",
										link: "",
										image: "",
									}]
								}))
							}}
						>
								+ Add
						</Button>
					</Grid>
						
					{footerData.socialMediaLinks.map((item, index) => (
						<React.Fragment key={`${index}-social-links`}>
							<Grid item
								xs={0.25}>
								<div style={{fontWeight: 'bold'}}>
									{index + 1}.
								</div>
							</Grid>
							<Grid item
								xs={2}>
								<TextField
									disabled={isLoading}
									label="Name (English)"
									value={item.name_en}
									onChange={(e) => handleArrayInputChange(index, 'name_en', e.target.value, 'socialMediaLinks')}
									fullWidth
								/>
							</Grid>
							<Grid item
								xs={3}>
								<TextField
									disabled={isLoading}
									label="Name (Arabic)"
									value={item.name_ar}
									onChange={(e) => handleArrayInputChange(index, 'name_ar', e.target.value, 'socialMediaLinks')}
									fullWidth
								/>
							</Grid>
							<Grid item
								xs={3}>
								<TextField
									disabled={isLoading}
									label="Link"
									value={item.link}
									onChange={(e) => handleArrayInputChange(index, 'link', e.target.value, 'socialMediaLinks')}
									fullWidth
								/>
							</Grid>
							<Grid item
								xs={3}>
								<TextField
									disabled={isLoading}
									label="Image (Base64)"
									dir="rtl"
									value={item.image}
									onChange={(e) => handleArrayInputChange(index, 'image', e.target.value, 'socialMediaLinks')}
									fullWidth
								/>
							</Grid>
							<Grid item
								xs={0.75}>
								<Button
									onClick={() => {
										setFooterData(data => ({
											...data,
											socialMediaLinks: data.socialMediaLinks.filter((_, t) => t !== index)
										}))
									}}
								>Delete</Button>
							</Grid>
						</React.Fragment>
					))}

					<Grid item
						style={{ marginTop: 16 }}>
						<Button
							variant="contained"
							color="primary"
							onClick={onSubmit}
							disabled={isLoading}
						>
                            Save
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</form>
	)
	
}

export default FooterManagementPage;