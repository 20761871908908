import PageHeading from "../components/PageHeading";
import { translate } from "../hooks/useTranslate";

import GalleryBanner from "../images/section-banner/gallery-head-banner.jpg"
import GalleryImage1 from "../images/gallery/1.jpg";
import GalleryImage2 from "../images/gallery/2.jpg";
import GalleryImage3 from "../images/gallery/3.jpg";
import GalleryImage4 from "../images/gallery/4.jpg";
import GalleryImage5 from "../images/gallery/5.jpg";
import GalleryImage6 from "../images/gallery/6.jpg";
import GalleryImage7 from "../images/gallery/7.jpg";
import GalleryImage8 from "../images/gallery/8.jpg";
import GalleryImage9 from "../images/gallery/9.jpg";
import { backgroundGray } from "../constants/colors";
import { Grid } from "@mui/material";

const PhotoGalleryPage = () => {
	const images = [
		GalleryImage1,
		GalleryImage2,
		GalleryImage3,
		GalleryImage4,
		GalleryImage5,
		GalleryImage6,
		GalleryImage7,
		GalleryImage8,
		GalleryImage9,
	  ];
	
	
	return (
		<>
			<PageHeading
				title={translate("photo_gallery")}
				backgroundImage={GalleryBanner}
			/>
			<div style={{
				backgroundColor: backgroundGray,
				padding: "50px 0px",
				display: "flex",
				flexDirection: "column",
				justifyContent: 'center',
				alignItems: "center" ,
			}}
			>
				<Grid
					style={{width: "70%"}}
					container
					justifyContent="center"
					alignItems="center"
					spacing={3}
				>
					{images.map((image, index) => (
						<Grid item
							xs={12}
							sm={6}
							md={4}
							display="flex"
							justifyContent="center"
							alignItems="center"
							key={index}>
							<img
								src={image}
								alt={`image-${index}`}
								// className={classes.image} 
							/>
						</Grid>
					))}
				</Grid>
			</div>
		</>
	)
}

export default PhotoGalleryPage;