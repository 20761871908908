import BrandLogo from "../images/logo.png";
import { BsFillTelephoneFill as PhoneLogo } from "react-icons/bs"
import useScrollPosition from "../hooks/useScrollPosition";
import { range } from "../functions/range";
import usePlatform from "../hooks/usePlatform";
import { GiHamburgerMenu } from "react-icons/gi"
import { offWhite } from "../constants/colors";
import { translate, useLanguage } from "../hooks/useTranslate";
import ROUTES from "../constants/routes";
import { Link } from "react-router-dom";
import { useDrawer } from "../context/DrawerContext";
import { PHONE_NUMBER } from "../constants/phoneNumber";
import { ORDER_NOW_LINK } from "../constants/orderNow";
import React, { useMemo } from "react";
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useCountry } from "../hooks/useCountry";

type HeaderLinkProps = {
	onClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void,
	newTab?: boolean,
	outline?: boolean,
	href: string,
	style?: {
		padding?: string,
		backgroundColor?: string,
		direction?: "rtl" | "ltr"
	},
	children: any
};

const HeaderLink: React.FC<HeaderLinkProps> =
	({ onClick, newTab, outline, children, style, href }) => {
		return <Link
			onClick={onClick}
			to={{
				pathname: href
			}}
			href={href}
			target={newTab ? "_blank" : undefined}
			rel="noreferrer"
			style={{
				color: "white",
				textDecoration: "none",
				display: "flex",
				alignItems: "center",
				justifyContent: 'center',
				gap: 10,
				...(
					outline ? {
						borderRadius: 40,
						borderStyle: "solid",
						borderColor: offWhite,
						borderWidth: "2px",
						padding: "6px 15px"
					} : {}
				),
				...style
			}}>
			{children}
		</Link>
	}




const Header = () => {
	const scrollPosition = useScrollPosition();
	const platform = usePlatform();
	const [lang, setLang] = useLanguage();
	const [country, setCountry] = useCountry();
	const [isDrawerOpen, setDrawer] = useDrawer();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const countryOpen = Boolean(anchorEl);

	const toggleDrawer = () => {
		setDrawer(!isDrawerOpen)
	}

	const handleCountryClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCountryClose = () => {
		setAnchorEl(null);
	};

	const toggleLang = () => {
		const revMap = {
			"en": "ar",
			"ar": "en"
		} as const

		setLang(revMap[lang]);
	}


	const CountryMenuGroup = useMemo(() => {
		return (
			<>
				<Menu
					open={countryOpen}
					onClose={handleCountryClose}
					onClick={handleCountryClose}
					anchorEl={anchorEl}
				>
					<MenuItem onClick={() => setCountry("kw")}>
						<span
							style={{
								width: "40px",
								height: "40px",
								borderRadius: 40,
							}}
							className="fi fi-kw fis"
						/>
						<span style={{ padding: 10 }}>{translate("kuwait")}</span>
					</MenuItem>
					<MenuItem onClick={() => setCountry("sa")}>
						<span
							style={{
								width: "40px",
								height: "40px",
								borderRadius: 40,
							}}
							className="fi fi-sa fis"
						/>
						<span style={{ padding: 10 }}>{translate("saudi")}</span>
					</MenuItem>
				</Menu>
				<Tooltip title="Country">
					<IconButton
						onClick={handleCountryClick}
						size="small"
						sx={{ ml: 2 }}
					>
						<Avatar sx={{
							width: 32,
							height: 32
						}}>
							<span
								style={{
									width: "40px",
									height: "40px",
									borderRadius: 40,
								}}
								className={`fi fi-${country} fis`}
							/>
						</Avatar>
					</IconButton>
				</Tooltip>
			</>
		)
	}, [countryOpen, handleCountryClose, setCountry, country])

	switch (platform) {
	case "desktop": {
		const height = range(0, 200, 100, 70, scrollPosition)
		const opacity = range(0, 200, 0, 1, scrollPosition);
		const backgroundColor = `rgba(147, 33, 31, ${opacity})`;
		return (
			<div style={{
				top: 0,
				position: "fixed",
				overflow: 'hidden',
				width: "100%",
				zIndex: 40
			}}>
				<div style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-evenly",
					alignItems: "center",
					backgroundColor: backgroundColor,
					padding: "1em",
					height,
					minHeight: 50,
				}}>
					<div style={{ width: "13%" }}></div>
					<HeaderLink
						onClick={(e) => {
							e.preventDefault();
							toggleLang()
						}}
						href="#">{translate(lang === "ar" ? "english" : "arabic")}</HeaderLink>
					<div style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: 10
					}}><PhoneLogo color="white" />
						<HeaderLink
							style={{ direction: "ltr" }}
							href={`tel:${PHONE_NUMBER}`}>+965 97422209</HeaderLink></div>
					<HeaderLink newTab
						outline
						href={ORDER_NOW_LINK}>{translate("order_now")}</HeaderLink>
					<HeaderLink href={ROUTES.FOOD_MENU.to}>{translate("food_menu")}</HeaderLink>
					<div style={{ height: "100%" }}>
						<Link to={{ pathname: ROUTES.HOME.to }}>
							<img src={BrandLogo}
								alt="300F Logo"
								style={{ height: "100%" }} />
						</Link>
					</div>
					<HeaderLink href={ROUTES.PHOTO_GALLERY.to}>{translate("photo_gallery")}</HeaderLink>
					<HeaderLink href={ROUTES.ABOUT_US.to}>{translate("about_us")}</HeaderLink>
					<HeaderLink
						outline
						href={ROUTES.BOOK_A_TABLE.to}
					>
						{translate("book_a_table")}
					</HeaderLink>
					{CountryMenuGroup}
					<div style={{ width: "20%" }}></div>
				</div>
			</div>
		)
	}
	case "mobile": {
		const height = range(0, 50, 50, 30, scrollPosition)
		const opacity = range(0, 100, 0, 1, scrollPosition);
		const backgroundColor = `rgba(147, 33, 31, ${opacity})`;
		return (<div style={{
			top: 0,
			position: "fixed",
			overflow: 'hidden',
			width: "100%",
			zIndex: 40
		}}>
			<div style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				backgroundColor: backgroundColor,
				padding: "1em",
				flexWrap: "wrap",
				height,
				minHeight: 30,
				overflow: "hidden"
			}}>
				<div style={{
					height: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: 'center',
					alignItems: "center",
					gap: 10
				}}>
					<div
						style={{
							cursor: "pointer",
							display: "flex"
						}}
						onClick={() => toggleDrawer()}><GiHamburgerMenu fontSize={30}
							color={offWhite} /></div>
					<div style={{ height: "100%" }}>
						<Link to={{ pathname: ROUTES.HOME.to }}><img src={BrandLogo}
							alt="300F Logo"
							style={{ height: "100%" }} /></Link>
					</div>
				</div>
				<div style={{
					display: "flex",
					flexDirection: "row",
					gap: 10,
					height: "100%"
				}}>
					{CountryMenuGroup}
					<HeaderLink outline
						newTab
						href={ORDER_NOW_LINK}>{translate("order_now")}
					</HeaderLink>
				</div>
			</div>
		</div>
		)
	}
	}
}


export { Header, HeaderLink };