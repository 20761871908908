import { BsFacebook, BsYoutube } from "react-icons/bs"
import { IoLogoWhatsapp } from "react-icons/io"
import { ImInstagram } from "react-icons/im"
import { darkGray, darkPrimaryColor, offWhite } from "../constants/colors";
import { translate, useLanguage } from "../hooks/useTranslate";
import ThreeFLink from "./ThreeFLink";
import LandingHeading from "./LandingHeading";
import { Link } from "react-router-dom";
import { ADDRESS } from "../constants/address";
import React, { useEffect, useState } from "react";
import { useCountry } from "../hooks/useCountry";


const GoogleMapEntry: React.FC<{title: string, src: string}> = ({title, src}) => {
	return (
		<div style={{
			border: 0,
			minWidth: "40%",
			height: "470px",
			overflow: "hidden",
		}}>
			<div style={{
				display: "flex",
				justifyContent: "center",
				paddingBottom: 20,
			}}>
				<LandingHeading 
					title={title}
					subtitle="" 
				/>
			</div>
			<iframe
				title="Map"
				style={{
					border: 0,
					width: "100%",
					height: "100%"
				}}
				loading="lazy"
				allowFullScreen={true}
				referrerPolicy="no-referrer-when-downgrade"
				src={src}>
			</iframe>
		</div>
	)
}

const GoogleMap = () => {
	const [country] = useCountry()

	if (country === 'kw') {

		return (
			<div style={
				{ 
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					flexWrap: "wrap",
					backgroundColor: "black",
					gap: 30,
					padding: "50px 0px"
				}}>
				<GoogleMapEntry
					title={translate("shuwaikh")}
					src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13912.018926632165!2d47.941232!3d29.3408571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd9864aa434ae92d3!2s300f%20smokehouse%20restaurant!5e0!3m2!1sen!2sin!4v1584146585211!5m2!1sen!2sin" 
				/>
				<GoogleMapEntry 
					title={translate("spoons")}
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6208.419689403323!2d48.12232653687841!3d29.154131360106007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf094960dd384b%3A0xb3262bd599143e3e!2s300F%20Smokehouse%20Restaurant!5e0!3m2!1sen!2skw!4v1678443116646!5m2!1sen!2skw" 
				/>
			</div>
		);
	} else {
		return null;
	}
}

export type FooterData = {
	contactUsEnglish: string;
	contactUsArabic: string;
	email: string;
	openingHours: OpeningHour[];
	socialMediaLinks: SocialMediaLink[];
  };
  
  type OpeningHour = {
	day_en: string;
	day_ar: string;
	hours_en: string;
	hours_ar: string;
  };
  
  type SocialMediaLink = {
	name_en: string;
	name_ar: string;
	link: string;
	image: string;
  };

const ShortInfo: React.FC = () => {
	const [footerData, setFooterData] = useState<undefined | FooterData>(undefined);
	const [lang] = useLanguage();

	useEffect(() => {
		fetch("/api/footer/", { method: 'GET'})
			.then(data => data.json())
			.then(x => setFooterData(x));
	}, [])
	
	
	if (footerData === undefined) {
		return null
	} else {
		return (
			<div style={{
				backgroundColor: "black",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				padding: "50px 0px"
			}}>
				<LandingHeading title={translate("short_info")}
					subtitle={translate("get_in_touch")} />
				<div className="footer">
					<div>
						<div style={{
							color: darkPrimaryColor,
							fontWeight: 700,
							fontSize: 20
						}}>{translate("contact_us")}</div>
						<div style={{ color: offWhite }}>
							{
								{
									en: footerData.contactUsEnglish,
									ar: footerData.contactUsArabic
								}[lang]
							}
						</div>
						<ThreeFLink style={{ color: "white" }}
							href={`mailto:${footerData.email}`}>{footerData.email}</ThreeFLink>
					</div>

					<div>
						<div style={{
							color: darkPrimaryColor,
							fontWeight: 700,
							fontSize: 20
						}}>{translate("working_hours")}</div>
						<div style={{
							display: "grid",
							gridTemplateRows: "1fr 1fr",
							gridTemplateColumns: "1fr 1fr"
						}}>
							{
								footerData.openingHours.map(openingDetails => (
									<>
										<div style={{ color: offWhite }}>{
											{
												en: openingDetails.day_en,
												ar: openingDetails.day_ar,
											}[lang]
										}</div>
										<div style={{ color: offWhite }}>{
											{
												en: openingDetails.hours_en,
												ar: openingDetails.hours_ar,
											}[lang]
										}</div>
									</>
								))
							}
						</div>
					</div>

					<div>
						<div style={{
							color: darkPrimaryColor,
							fontWeight: 700,
							fontSize: 20
						}}>{translate("follow_us")}</div>
						<div style={{
							display: "grid",
							gridTemplateColumns: "1fr 1fr 1fr 1fr",
							gridTemplateRows: "1fr"
						}}>
							{
								footerData.socialMediaLinks.map(social => (
									<div style={{
										color: offWhite,
										display: "flex",
										flexDirection: "column",
										alignItems: "center"
									}}>
										
										<a 
											style={{ color: "white",
												display: 'flex',
												flexDirection: "column",
												alignItems: 'center',
												textDecoration: "none",
											}}
											target="_blank"
											href={`${social.link}`}
										>
											<img
												style={{
													height: "25px",
													width: "25px"
												}} 
												src={`${social.image}`}
											/>
											{
												{
													en: social.name_en,
													ar: social.name_ar
												}[lang]
											}
										</a>
									</div>
								))
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const Copyrights = () => {
	const currentYear = new Date().getFullYear();
	return (
		<div style={{
			backgroundColor: darkGray,
			padding: "10px 0px",
			display: 'flex',
			flexDirection: "column",
			alignItems: "center"
		}}>
			<div style={{ color: offWhite }}>{currentYear}&copy; - {translate("all_rights")}</div>
			<div style={{ color: offWhite }}>{translate("developed_by")} <ThreeFLink href="https://www.linkedin.com/in/mohammed-alhaddar-720bb3180/">{translate("dev_name")}</ThreeFLink></div>
		</div>
	)
}

const Footer = () => {
	return (
		<>
			<GoogleMap />
			<ShortInfo />
			<Copyrights />
		</>
	)
}

export default Footer;
