
import { CircularProgress, LinearProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useEffect } from 'react';
import { forwardRef, useReducer, useState } from 'react';
import { flushSync } from 'react-dom';
import { useCommandDialogContext } from '../context/CommandDialogContext';
import { translate } from '../hooks/useTranslate';

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up"
		ref={ref}
		{...props} />;
});

type CommandSubmissionState = "IDLE" | "SUBMITTED" | "SUCCEED" | "UNKNOWN_ERROR" | "VALIDATION_ERROR"

type CommandSubmissionText = {
	title: string,
	color: "red" | "green" | "black"
}


const CommandDialog: React.FC = () => {
	const [{ isOpen, spec }, setCommandDialogSpec] = useCommandDialogContext();
	const [commandSubmissionState, setCommandSubmissionState] = useState<{state: CommandSubmissionState, message?: string}>({state: "IDLE"});
	const [commandRequestBody, setCommandRequestBody] = useReducer(
		(prev: any, newState: any) => {
			if (newState === undefined) {
				return {};
			} else {
				return {
					...prev,
					...newState
				}
			}
		},
		{}
	);


	
	const CommandSubmissionStateToTextMap: Record<CommandSubmissionState, CommandSubmissionText> = {
		IDLE: {
			color: "black",
			title: " "
		},
		SUBMITTED: {
			color: "black",
			title: translate("submitted")
		},
		VALIDATION_ERROR: {
			color: "red",
			title: translate("validation_error")
		},
		UNKNOWN_ERROR: {
			color: "red",
			title: translate("an_error_occurred")
		},
		SUCCEED: {
			color: "green",
			title: translate("submitted_successfully")
		}
	}

	useEffect(() => {
		setCommandRequestBody(spec?.initialState)
	}, [spec?.initialState])

	useEffect(() => {
		if (spec?.autoSubmit) {
			handleSubmit(spec?.initialState)
		}
	}, [spec?.autoSubmit])

	const handleSubmit = (commandRequestBody: any) => {
		setCommandSubmissionState({state: "SUBMITTED"})
		spec?.actionFun(commandRequestBody)
			.then(res => {
				if (res.status === 200) {
					setCommandSubmissionState({state: "SUCCEED"});
					setTimeout(() => {
						handleClose();
					}, 1000)
				} else if (res.status === 400) {
					res.json().then(({errorType, data}) => {
						switch (errorType) {
						case "INPUT_VALIDATION_ERROR": {
							setCommandSubmissionState({
								state: "VALIDATION_ERROR",
								message: data.fieldErrors.map((err: any) => `[${err.field}]: ${err.defaultMessage}`).join(".\n ")
							});
							return;
						}
						case "LOGIC_VALIDATION_ERROR": {
							setCommandSubmissionState({
								state: "VALIDATION_ERROR",
								message: data.join(".\n ")
							});	
							return;
						}
						case "INTERNAL_ERROR": {
							setCommandSubmissionState({
								state: "VALIDATION_ERROR",
								message: data.join(".\n ")
							});	
							return;
						}
						default: {
							setCommandSubmissionState({state: "UNKNOWN_ERROR"});			
						}
						}
					})
				} else {
					setCommandSubmissionState({state: "UNKNOWN_ERROR"});
				}
			})
	}

	const handleClickOpen = () => {
		setCommandDialogSpec((x) => ({
			...x,
			isOpen: true
		}))
	};

	const handleClose = () => {
		setCommandSubmissionState({state: "IDLE"})
		setCommandRequestBody(undefined);
		setCommandDialogSpec(() => ({
			isOpen: false,
			spec: undefined
		}))
	};

	return (
		<div>
			<Dialog
				open={isOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				fullWidth
				disableScrollLock
			>
				<DialogTitle>{spec?.title}</DialogTitle>
				<form onSubmit={e => {
					e.preventDefault();
					handleSubmit(commandRequestBody)
				}}>
					<DialogContent>
						{spec?.body && spec?.body(commandRequestBody, setCommandRequestBody)}
					</DialogContent>
					<DialogContent style={{ padding: "0px 24px 10px 24px" }}>
						<Typography
							variant="subtitle1"
							color={CommandSubmissionStateToTextMap[commandSubmissionState.state].color}
						>
							{commandSubmissionState.message || CommandSubmissionStateToTextMap[commandSubmissionState.state].title}
						</Typography>
					</DialogContent>
					{
						<DialogActions>
							<Button
								variant="outlined"
								disabled={spec?.autoSubmit && !['UNKNOWN_ERROR', 'VALIDATION_ERROR'].includes(commandSubmissionState.state)}
								onClick={handleClose}
							>
								{translate("cancel")}
							</Button>
							<Button
								variant="contained"
								disabled={spec?.autoSubmit || commandSubmissionState.state === 'SUBMITTED' || commandSubmissionState.state === "SUCCEED"}
								type="submit"
							>
								{translate("submit")}
							</Button>
						</DialogActions>
					}
				</form>
				{['SUCCEED', 'SUBMITTED'].includes(commandSubmissionState.state) && <LinearProgress />}
			</Dialog>
		</div>
	);
}

export default CommandDialog;