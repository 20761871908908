
import { darkPrimaryColor, offWhite } from "../constants/colors";

type PageHeadingProps = {
	title: string;
	backgroundImage: string;
}

const PageHeading: React.FC<PageHeadingProps> = ({ title, children, backgroundImage }) => {
	return (
		<div style={{
			height: "700px",
			backgroundImage: `url(${backgroundImage})`,
			backgroundSize: "cover"
		}}>
			<div style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "100%",
				justifyContent: "center"
			}}>
				<span
					style={{
						fontSize: 52,
						fontWeight: "bold",
						color: offWhite,
						textShadow: `${darkPrimaryColor} 2px 2px, ${darkPrimaryColor} 3px 3px`
					}}>
					{title}
				</span>
				{children}
			</div>
		</div>
	)
}

export default PageHeading;